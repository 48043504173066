export const TimeZones = [
    { value: 2, name: 'МСК-1' },
    { value: 3, name: 'МСК' },
    { value: 4, name: 'МСК+1' },
    { value: 5, name: 'МСК+2' },
    { value: 6, name: 'МСК+3' },
    { value: 7, name: 'МСК+4' },
    { value: 8, name: 'МСК+5' },
    { value: 9, name: 'МСК+6' },
    { value: 10, name: 'МСК+7' },
    { value: 11, name: 'МСК+8' },
    { value: 12, name: 'МСК+9' },
  ];

  export function formatDate(originalDateStr: any): string {
    const originalDate = new Date(originalDateStr);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };
  
    const formattedDate = originalDate.toLocaleDateString('ru-RU', options);
  
    return `${formattedDate}`;
  }

  export function formatDateWithoutTime(originalDateStr: any): string {
    const originalDate = new Date(originalDateStr);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
  
    const formattedDate = originalDate.toLocaleDateString('ru-RU', options);
  
    return `${formattedDate}`;
  }