import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Button,
  TextField, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Grid,
} from '@mui/material';
//import axios from 'axios';
import getTypesIds from '../../../Api/HomeIk/getTypesIds';
import getIks from '../../../Api/HomeIk/getIks';
import { TimeZones } from '../../../data/Utils/timeZones';
import getInfoIk from '../../../Api/HomeIk/getInfoIk';
import enumRegions from '../../../Api/Regions/enumRegions';
import enumDistricts from '../../../Api/Districts/enumDistricts';
import { determineRoleCTYPE } from '../../../data/Utils/accessControlConst';
import { useSelector } from 'react-redux';

const addIksForm = ({ addPollingStation, editData, handleUpdate }) => {
  const [pollingStation, setPollingStation] = useState({
    name: '',
    number: '0',
    address: '',
    lat: '',
    long: '',
    phone: '',
    district_id: null,
    reg_id: null,
    parent_id: null,
    type_id: null,
    pcount_std: 0,
    tz_offset: null,
    meta: "{}"
  });
  const [typesId, setTypesId] = useState([])
  const token = useSelector((state) => state.user.token);
  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const userInfo = useSelector((state) => state.user.user);
  

  useEffect(() => {
    if (editData){
      setPollingStation(editData)

      if(editData.parent_id){
        getInfoIk({"ik":{ "ik_id": editData.parent_id }}).then((resp) => {
          if(resp.success){
            setSelectedYik(resp.data.ik)
          }
        })
      }

      if(editData.district_id){
         enumDistricts({"region":{"region_id": editData.reg_id}}).then((response) => {
          if (response.success){
            setDistricts(response.data.districts)
          } else {
            alert(response.message)
          }
        })
      }
    }
  }, [editData])

  useEffect(() => {

    if(!editData && pollingStation.type_id){
      if (pollingStation.type_id === 3) {
        setPollingStation({ ...pollingStation, number: 'ТИК'});
    } else if (!editData && pollingStation.type_id === 2) {
        setPollingStation({ ...pollingStation, number: 'РИК'});
      } else if (!editData && pollingStation.type_id === 1 || pollingStation.type_id === 4) {
        setPollingStation({ ...pollingStation, number: '0'});
      }
    }

  }, [pollingStation.type_id])

  const handleChange = async (e) => {

    if (e.target.name === 'reg_id'){
       setPollingStation({ ...pollingStation, [e.target.name]: e.target.value});
       await enumDistricts({"region":{"region_id":e.target.value}}).then((response) => {
         if (response.success){
           setDistricts(response.data.districts)
         } else {
           alert(response.message)
         }
       })
     } else if (e.target.name === 'pcount_std'){
      setPollingStation({ ...pollingStation, [e.target.name]: parseInt(e.target.value)});
    } else {
      setPollingStation({ ...pollingStation, [e.target.name]: e.target.value });
    }
  }
  
  const getTypeIds = async () => {
   await getTypesIds({}).then((response)=>{
     if (response.success) setTypesId(response.data.ik_types)
     else {
      setTypesId([])
      alert(response.message)
    }
   })
  }

   // Обработчик выбора значения в Autocomplete
const handleAutocompleteChange = (event, newValue) => {
  setSelectedYik(newValue);
  // Записываем значение УИКа в user.home_ik
  setPollingStation({ ...pollingStation, parent_id: newValue.ik_id });
}

const handleSubmit = async (e) => {
  e.preventDefault();
  if(editData){
    handleUpdate(pollingStation)
  } else {
    addPollingStation(pollingStation);
  }
};

  useEffect(()=> {
    getTypeIds();
    enumRegions({}).then((response) => {
      if (response.success){
        setRegions(response.data.regions)
      } else {
        setRegions([])
        alert(response.message)
      }
    })
  }, [])

  ///БЛОК ПОИСКА ПО ИК

  const handleSearch = async (event, newValue, reason) => {

    if (reason === 'reset') {
      setFilteredYiks([]);
      return
    }
  
    if (event?.type === 'click' || event === null || event?.target === null) {
      return;
     }
  
     const searchTerm = event.target.value;
  
    // Проверка, если строка поиска пустая
    if (searchTerm.length === 0) {
      setFilteredYiks([]); // Очищаем результаты поиска
      return;
    }
  
    if (searchTerm.length <= 2) {
      return;
    }
  
  setSearchLoading(true);
  
    //Временное решение проблемы для быстрого поиска ЦИКа
    try {
      // Запрос списка yiks с сервера для текущей страницы
      const response = await getIks({
        "user_initiator": {
          "usr_token": token
        },
        "ik": {
          "type_id":pollingStation.type_id === 1 ? pollingStation.type_id : pollingStation.type_id - 1,
          "name": searchTerm,
        },
        //"page": currentPage
      });
      
      if(response.success){
        const yiks = response.data.iks;
        setFilteredYiks(yiks);
        setSearchLoading(false);
      }
    } catch (error) {
      console.error("Ошибка при получении списка yiks:", error);
      // Обработка ошибки, например, отображение сообщения об ошибке пользователю
      setSearchLoading(false);
    }
  };

   // БЛОК С ОГРАНИЧИТЕЛЕМ ПО ТИПАМ:

   const filteredTypes = typesId.filter((type) => {
    const allowedAccess = determineRoleCTYPE(userInfo.access_data.access_id);
    switch (allowedAccess[0]) {
      case 35: // ROOT
      case 36: // Центральный super
      case 37: // Центральный admin
      case 38: // Центральный mod
        return true; // Видят все типы
      case 39: // Региональный super
      case 40: // Региональный admin
      case 41: // Региональный mod
      case 42: // Районный super
      case 43: // Районный admin
      case 44: // Районный mod
        return type.type_id !== 2 && type.type_id !== 1; // Исключить "Федеральный"
      // case 42: // Районный super
      // case 43: // Районный admin
      // case 44: // Районный mod
        //return type.type_id !== 2 && type.type_id !== 1; // Исключить "Региональный"
      default:
        return false; // Если доступ не указан, исключить все
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        {/* <FormControl fullWidth margin="none"> */}
        <InputLabel id="ik-label">Название ИК</InputLabel>
          <TextField
            margin="none"
            name="name"
            //label="Название ИК"
            labelId="ik-label"
            value={pollingStation.name}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* </FormControl> */}

         {/* <FormControl fullWidth margin="none"> */}
            {/* <InputLabel shrink={pollingStation.reg_id ? true : false}>Выберите регион</InputLabel> */}
            <InputLabel id="ik-region">Выберите регион</InputLabel>
            <Select
              name="reg_id"
              id="region-selector"
              labelId='ik-region'
              value={pollingStation.reg_id}
              onChange={e => handleChange(e)}
              fullWidth // добавлено
              // InputLabelProps={{
              //   shrink: true,
              // }}
            >
              {regions.map(region => (
                <MenuItem key={region.region_id} value={region.region_id}>{region.name}</MenuItem>
              ))}
            </Select>
          {/* </FormControl> */}

          {/* <FormControl fullWidth margin="normal"> */}
            <InputLabel id="type-ik-label">Выберите уровень ИК</InputLabel>
            {/* <InputLabel shrink={pollingStation.type_id ? true : false}>Выберите уровень ИК</InputLabel> */}
            <Select
              name="type_id"
              labelId='type-ik-label'
              value={pollingStation.type_id}
              onChange={e => handleChange(e)}
              fullWidth // добавлено
            >
              {filteredTypes.map(type => (
                <MenuItem key={type.type_id} value={type.type_id}>{type.name} - {type.name_short}</MenuItem>
              ))}
            </Select>
          {/* </FormControl> */}

          {pollingStation.type_id !== null && pollingStation.type_id !== 1 && pollingStation.type_id !== 2 &&
            // <FormControl fullWidth margin="normal">
              // <InputLabel>Выберите район</InputLabel>
              <>
              <InputLabel id="district-ik-selector">Выберите район</InputLabel>
              <Select
                name="district_id"
                labelId='district-ik-selector'
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                value={pollingStation.district_id}
                onChange={e => handleChange(e)}
                fullWidth // добавлено
              >
                {districts.map(district => (
                  <MenuItem key={district.district_id} value={district.district_id}>{district.district_name}</MenuItem>
                ))}
              </Select>
            {/* // </FormControl> */}
            </>
          }

        {pollingStation.type_id && pollingStation.type_id == 2 &&
            <>
            <InputLabel id="tz-ik-selector">Временная зона</InputLabel>
            <Select
              margin="dense"
              name="tz_offset"
              labelId='tz-ik-selector'
              value={pollingStation.tz_offset}
              onChange={e => handleChange(e)}
              fullWidth // добавлено
            >
              {TimeZones.map(time => (
                <MenuItem key={time.value} value={time.value}>{time.name}</MenuItem>
              ))}
            </Select>
            </>
           }

         {(editData === null || pollingStation.parent_id) && 
         <>
         <InputLabel id="parent-ik-field">Родительская ИК</InputLabel>
            <FormControl fullWidth margin="none">
              <Autocomplete
                value={selectedYik}
                options={filteredYiks}
                loading={searchLoading}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} labelid='parent-ik-field' />}
                noOptionsText="Ничего не найдено"
                loadingText="Идет поиск. Пожалуйста, подождите!"
              />
            </FormControl>
            </>
          }
          
          {pollingStation.type_id && pollingStation.type_id === 4 &&
          <>
          <InputLabel id="pcount-ik-field">Количество избирателей</InputLabel>
          <TextField
            margin="none"
            name="pcount_std"
            // label="Количество избирателей"
            labelid='pcount-ik-field'
            value={pollingStation.pcount_std || 0}
            onChange={handleChange}
            fullWidth
          />
          </>
           }         
        </Grid>
        <Grid item xs={6}>
        {pollingStation.type_id !== null && pollingStation.type_id === 4 &&
        <>
        <InputLabel id="number-ik-field">Номер избирательной комиссии</InputLabel>
        <TextField
            margin="none"
            name="number"
            //label="Адрес ИК"
            labelId='number-ik-field'
            value={pollingStation.number}
            onChange={handleChange}
            fullWidth
          />
          </>
        }
        <InputLabel id="address-ik-field">Адрес ИК</InputLabel>
        <TextField
            margin="none"
            name="address"
            //label="Адрес ИК"
            labelId='address-ik-field'
            value={pollingStation.address}
            onChange={handleChange}
            fullWidth
          />
          <InputLabel id="lat-ik-field">Широта</InputLabel>
          <TextField
            margin="none"
            name="lat"
            //label="Широта"
            labelId='lat-ik-field'
            value={pollingStation.lat}
            onChange={handleChange}
            fullWidth
          />
          <InputLabel id="long-ik-field">Долгота</InputLabel>
          <TextField
            margin="none"
            name="long"
            //label="Долгота"
            labelId='long-ik-field'
            value={pollingStation.long}
            onChange={handleChange}
            fullWidth
          />
          <InputLabel id="phone-ik-field">Телефон ИКа</InputLabel>
          <TextField
            margin="none"
            name="phone"
            //label="Телефон ИКа"
            labelId='phone-ik-field'
            value={pollingStation.phone}
            onChange={handleChange}
            fullWidth
          />

        </Grid>
      </Grid>
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: 10 }}>
        {editData ? "Обновить" : "Добавить"}
      </Button>
    </form>
  );
};

addIksForm.propTypes = {
  addPollingStation: PropTypes.func,
  editData: PropTypes.object,
  handleUpdate: PropTypes.func,
};

export default addIksForm;