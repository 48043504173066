import { useState, useEffect } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import AddDistrictForm from './addDistrictForm';
import { Box, Button, Dialog ,DialogContent, DialogTitle, DialogActions, Typography  } from '@mui/material';
import Header from "../../components/Header";
import enumDistricts from '../../Api/Districts/enumDistricts';
import enumRegions from '../../Api/Regions/enumRegions';
import deleteDistricts from '../../Api/Districts/deleteDisticts';
import { dataGridStyles } from '../../styleGrid';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import importData from '../../Api/ImportData/importData';
import { useSelector } from 'react-redux';

const addDistrict = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [districts, setDistricts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    enumDistricts({})
      .then(response => {
        setDistricts(response.data.districts);
      })
      .catch(error => {
        console.error('Error fetching districts:', error);
      });

    enumRegions({})
      .then(response => {
        setRegions(response.data.regions);
      })
      .catch(error => {
        console.error('Error fetching districts:', error);
      });

      setForce(false);
  }, [force]);

  const handleDistrictAdded = () => {
    setForce(true);
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Название района', width: 200 },
    { field: 'region', headerName: 'Регион', width: 150 },
    {
      field: "actions",
      headerName: "Управление",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          style={{color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13}}
          onClick={() => handleDeleteDistrict(params.row.id)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  const rows = districts.map(district => {
    const region = regions.find(region => region.region_id === district.reg_id);
    return {
      id: district.district_id,
      name: district.district_name,
      region: region ? region.name : '',
    };
  });

  const handleDeleteDistrict = async (district) => {
    await deleteDistricts({
      "user_initiator":{
        "usr_token": token
    },
      "district":{
          "district_id":district
      }
    })
      .then((response) => {
        if (response.success){
          setForce(true);
        } else {
          alert(response.message)
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAddDialog = () => (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">{"Настройте район"}</DialogTitle>
        <DialogContent>
          <AddDistrictForm onDistrictAdded={handleDistrictAdded} />
        </DialogContent>
      </Dialog>
    </div>
  );

  //БЛОК С ИМПОРТОМ

  const [openImport, setOpenImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let exampleImport = 'https://redcontrol.kprf.ru/files/ExamplesImport/testDataDistricts.xlsx'

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
    setSelectedFile(null);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const downloadFile = (url) => {
    // Создаем ссылку на скачивание
    const link = document.createElement('a');
    link.href = url;
    // Устанавливаем атрибуты для скачивания файла
    link.setAttribute('download', '');
    // Добавляем ссылку на страницу (это необходимо для некоторых браузеров)
    document.body.appendChild(link);
    // Нажимаем на ссылку, чтобы начать скачивание файла
    link.click();
    // Удаляем ссылку после скачивания
    document.body.removeChild(link);
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setSelectedFile(file);
    } else {
      alert('Please select a valid .xlsx file', { variant: 'error' });
    }
  };

  const handleFileUpload = () => {
    importData(selectedFile, token, "districts").then((resp) => {
      if(resp.success){
        alert('Данные успешно импортированы');
      } else {
        alert(`Ошибка при импорте данных: ${resp.message}`)
      }
    })
  };

  const handleOpenDialogImport = () => {
    return (
      <div>
      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle>Импорт файла</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
          {selectedFile ? (
            <Box display="flex" alignItems="center">
              <Typography>{selectedFile.name}</Typography>
              <Button variant="outlined" color="secondary" onClick={handleFileRemove} style={{ marginLeft: 16 }}>
                Отменить
              </Button>
            </Box>
          ) : (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} component="label">
              Добавить файл
              <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
            </Button>
          )}
          </Box>
        </DialogContent>
        <DialogActions>
          {selectedFile && (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleFileUpload}>
              Загрузить
            </Button>
          )}
          <>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={() => downloadFile(exampleImport)}>
             Скачать пример
          </Button>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleCloseImport}>
            Закрыть
          </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
    )
  }

//БЛОК КНОПОК В ФИЛЬТРЕ
  const buttonsElements = () => {
    return (
      <>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить район
      </Button>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold', marginLeft: 10}}  onClick={handleOpenImport}>
         Импорт
      </Button>
      </>
    )
  }


  return (
    <Box m="20px">
    <Header title="Добавление районов" subtitle="Добавьте районы в систему" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
        {handleOpenAddDialog()}
        {handleOpenDialogImport()}
        <DataGrid rows={rows} columns={columns} components={{ Toolbar: buttonsElements }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />
        </Box>
    </Box>
  );
};

export default addDistrict;



// import { useEffect, useState } from 'react';
// import { Box, useTheme, Button, Dialog ,DialogContent , Typography  } from '@mui/material';
// import axios from 'axios';
// import { tokens } from '../../theme';
// import { DataGrid } from "@mui/x-data-grid";
// import Header from "../../components/Header";
// import AddDistrictForm from "./addDistrictForm"
// import { styled } from '@mui/material/styles';

// const addDistrict = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [regions, setRegions] = useState([]);
//   const [district, setDistrict] = useState([]);
//   const [open, setOpen] = useState(false);

//   const ColorButton = styled(Button)(() => ({
//     m:"0 auto",
//     p:"5px",
//     display:"flex",
//     justifyContent:"center",
//     backgroundColor: colors.greenAccent[600],
//     borderRadius: "4px",
//     width: "50%",
//     '&:hover': {
//       backgroundColor: colors.greenAccent[600],
//     },
//   }));

//   const columns = [
//     { field: "id", headerName: "ID" },
//     {
//       field: "region",
//       headerName: "Регион",
//       flex: 1,
//       cellClassName: "name-column--cell",
//     },
//     {
//       field: "countDist",
//       headerName: "Добавленных районов в регионе",
//       flex: 1,
//       cellClassName: "name-column--cell",
//       renderCell: ({ row }) => {
//         // Find the districts corresponding to the current region
//         const districtsForRegion = district.filter((item) => item.region === row.region);
//         return (
//                 <Box
//                   width="50%"
//                   m="0 auto"
//                   p="5px"
//                   display="flex"
//                   justifyContent="center"
//                   backgroundColor={colors.greenAccent[600]}
//                   borderRadius="4px"
//                 >
//                   <Typography color={colors.grey[100]} sx={{ ml: "5px", }}>
//                   Районов добавлено: {districtsForRegion.length}
//                   </Typography>
//                 </Box>
//         );
//       },
//     },
//     {
//       field: "Distr",
//       headerName: "Управление",
//       flex: 1,
//       flexDirection: 'column',
//       cellClassName: "name-column--cell",
//       renderCell: ({ row }) => {
//         // Find the districts corresponding to the current region
//         district.filter((item) => item.region === row.region);
//         return (
//           <>
//                  <ColorButton
//                     variant="contained"
//                     onClick={() => handleOpenAddDistrictDialog(row.region)}
//                   >
//                     Добавить районы
//                </ColorButton>
           
//           </>
//         );
//       },
//     },
//   ];

//   // Modify the rows prop for the DataGrid to include unique regions from the regions data
//   const uniqueRegions = [...new Set(regions.map((item) => item.region))];
//   const rows = uniqueRegions.map((region, index) => ({ id: index + 1, region }));

//   useEffect(() => {
//     axios.get('http://localhost:3000/regions').then((response) => {
//       setRegions(response.data);
//     });
//     axios.get('http://localhost:3000/districts').then((response) => {
//       setDistrict(response.data);
//     });
//   }, []);

//   const [selectedRegion, setSelectedRegion] = useState(null);

//   const addRegion = (region, newDistrict) => {
//     // Create a new district object with a random ID
//     const id = Math.floor(Math.random() * 10000);
//     const newDistrictObj = { id, region, district: newDistrict };

//     // Add the new district to the existing districts list
//     setDistrict([...district, newDistrictObj]);
//   };

//   const handleOpenAddDistrictDialog = (region) => {
//     setSelectedRegion(region);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };


//   //components={{ Toolbar: handleOpenAddDialog  }}

//   return (
//     <Box m="20px">
//     <Header title="Добавление регионов" subtitle="Добавьте регионы в систему" />
//       <Box
//         m="40px 0 0 0"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .name-column--cell": {
//             color: colors.greenAccent[300],
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: colors.blueAccent[700],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: colors.primary[400],
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: colors.blueAccent[700],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${colors.greenAccent[200]} !important`,
//           },
//         }}
//       >
//        <DataGrid checkboxSelection rows={rows} columns={columns}/> 
//     </Box>
//     <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
//     <DialogContent>
//         <AddDistrictForm
//           selectedRegion={selectedRegion}
//           districtsForRegion={district.filter((item) => item.region === selectedRegion).map((item) => item.district)}
//           addRegion={addRegion}
//           onClose={handleClose}
//         />
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// };

// export default addDistrict;