import {
  Button, 
  Card, 
  CardActions, 
  CardHeader, 
  CardMedia,
  CardContent,
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  //IconButton
} from '@mui/material';
import ReplySharpIcon from '@mui/icons-material/ReplySharp';
import { useState } from 'react';
import { FilesIcon, IconFire } from '../../../Icons/IconsSVG';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
//import { ReactComponent as VKIcon } from '../../../Icons/vk-icon.svg'; // Assuming you have a custom VK icon SVG

const MessageItem = (data) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const maxLength = 288;

  const handleExpand = () => setExpanded(!expanded);
  const textToShow = expanded ? data.data.text : data.data.text.slice(0, maxLength);

  // Social share handlers
  const handleShareMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleShareMenuClose = () => setAnchorEl(null);

  // const handleShareToVK = () => {
  //     const vkUrl = `https://vk.com/share.php?url=${encodeURIComponent(data.data.title)}&description=${encodeURIComponent(data.data.text)}&image=${encodeURIComponent(data.data.file_links[0])}`;
  //     window.open(vkUrl, '_blank');
  //     handleShareMenuClose();
  // };

  const handleShareToTelegram = () => {
    const title = data.data.title;
    const text = data.data.text;
    const fileLinks = data.data.file_links
        .map((link) => encodeURIComponent(link))
        .join('%0A'); // %0A is the URL encoding for a line break

    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(title)}&text=${encodeURIComponent(text)}%0A${fileLinks}`;
    window.open(telegramUrl, '_blank');
    handleShareMenuClose();
};

const handleShareToWhatsApp = () => {
  const title = data.data.title;
  const text = data.data.text;
  const fileLinks = data.data.file_links.join('\n');

  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + '\n\n' + text + '\n\n' + fileLinks)}`;
  window.open(whatsappUrl, '_blank');
  handleShareMenuClose();
};
  const title = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: 'white' }}>{data.data.userName}</span>
          <span style={{ color: 'white', fontSize: 12 }}>{data.data.role}</span>
      </div>
  );

  const content = (
      <CardContent style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 7 }}>
                  <span style={{ color: 'black', fontSize: 16 }}>
                      {textToShow}
                      {data.data.text.length > maxLength && !expanded && '...'}
                      {!expanded && data.data.text.length > maxLength && (
                          <Button onClick={handleExpand} variant="text" size="small" color="primary">Развернуть</Button>
                      )}
                      {expanded && (
                          <Button onClick={handleExpand} variant="text" size="small" color="primary">Свернуть</Button>
                      )}
                  </span>
              </Box>
          </Box>
      </CardContent>
  );

  const footer = (
      <CardMedia style={{ display: 'flex', flexDirection: 'column', marginTop: 15, marginLeft: 50 }}>
          <Typography style={{ color: 'black', fontSize: 20, fontWeight: '500' }}> ДАТА ГОЛОСОВАНИЯ: </Typography>
          <Typography style={{ color: '#C40B0B', fontSize: 18, fontWeight: '700' }}>  {data.data.starts_at_el} - {data.data.ends_at_el}</Typography>

          <Typography style={{ color: 'black', fontSize: 20, fontWeight: '500' }}> Уровень выборов: </Typography>
          <Typography style={{ color: '#C40B0B', fontSize: 18, fontWeight: '700' }}> {data.data.election_type} </Typography>

          <Typography style={{ color: 'black', fontSize: 20, fontWeight: '500' }}> Выборы: </Typography>
          <Typography style={{ color: '#C40B0B', fontSize: 18, fontWeight: '700' }}> {data.data.election_name} </Typography>

          <Typography style={{ color: 'black', fontSize: 20, fontWeight: '500' }}> Прислано из: </Typography>
          <Typography style={{ color: '#C40B0B', fontSize: 18, fontWeight: '700' }}> {data.data.address} </Typography>
      </CardMedia>
  );

  const avatar = (
      <img src={require("../../../Icons/free-user-icon-3296-thumb.png")} alt="" style={{ width: 50, height: 50 }} />
  );

  return (
    <>
      <Card sx={{display: 'flex', flexDirection: 'column', backgroundColor: '#D9D9D9', boxShadow: "rgb(145 158 171 / 50%) 0px 0px 2px 0px, rgb(145 158 171 / 50%) 0px 16px 32px -4px", width: '50%', borderRadius: 10, marginTop: 2}}>
        <CardHeader
          title={title}
          subheader={<span>{data.data.time}</span>}
          avatar={avatar}
          //action={iconMenu}
          //subheader={data.data.subtitle}
          sx={{
              display:'flex',
              //padding: "24px 24px 0 24px",
              backgroundColor:'#CD0C0C',
      
              "& .MuiCardHeader-title": {
                fontSize: 16,
                fontWeight: 600,
                color: "white",
              },
              "& .MuiCardHeader-subheader": {
                fontSize: 14,
                fontWeight: 300,
                color: "white",
              },
            }}
        />
         <Grid container>
            {/* Left column */}
            <Box sx={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-start',alignItems: 'flex-end',marginLeft: 3, width: '100%', marginTop: 2}}>
                <div dangerouslySetInnerHTML={{ __html: IconFire }} />
               <Typography style={{color: 'black', fontSize: 24, fontWeight: 'bold', marginLeft: 10}}> {data.data.title}</Typography>
            </Box>
            <Grid item xs={12} sm={7} order={{ xs: 1, sm: 1 }}>
                {content}
                <>
                {data.data.file_links.length > 0 &&
                <>
                <Box sx={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-start',alignItems: 'flex-end', width: '100%', marginTop: 3 ,marginLeft: 3}}>
                    <div dangerouslySetInnerHTML={{ __html: FilesIcon }} />
                 <Typography style={{color: 'black', fontSize: 18, fontWeight: 'bold', marginLeft: 10}}> Прикрепленные файлы: </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection:'row', flexWrap: 'wrap', marginLeft: 7, marginTop: 3}}>
                    {data.data.file_links.map((file, index) => 
                      <a key={index} href={file} target="_blank" rel="noopener noreferrer" style={{marginLeft: 10}}>
                        <img src={file} style={{width: 80, height: 80, borderRadius: 21}}/>
                       {/* <Typography style={{color: 'red', fontSize: 16}}> {Файл ${index + 1}} </Typography> */}
                      </a>
                    )}
                </Box>
                  </>
                 }
               </>
            </Grid>
            {/* Right column */}
            <Grid item xs={12} sm={5} order={{ xs: 2, sm: 2 }}>
                {footer}
            </Grid>
         </Grid>

              <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                      variant="outlined"
                      onClick={handleShareMenuOpen}
                      sx={{ ml: 1, color: 'black', border: 'none' }}
                  >
                      <ReplySharpIcon />
                      <Typography style={{ color: 'black', fontSize: 15, fontWeight: '500', marginLeft: 10, marginRight: 10 }}> Сделать репост </Typography>
                  </Button>
                  <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleShareMenuClose}
                  >
                      {/* <MenuItem onClick={handleShareToVK}>
                        <VKIcon style={{ width: 20, height: 20, marginRight: 10 }} />
                          VK
                      </MenuItem> */}
                      <MenuItem onClick={handleShareToTelegram}>
                          <TelegramIcon style={{ color: '#0088cc', marginRight: 10 }} />
                          Telegram
                      </MenuItem>
                      <MenuItem onClick={handleShareToWhatsApp}>
                          <WhatsAppIcon style={{ color: '#25D366', marginRight: 10 }} />
                          WhatsApp
                      </MenuItem>
                  </Menu>
              </CardActions>
          </Card>
      </>
  );
};

export default MessageItem;