export function determineUnit(number: number) {
    return number < 10000 ? "чел" : "тыс. чел";
  }

export const tryParseJSON = (jsonString : any) => {
  if (!jsonString) return null;
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};