import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  IconButton, 
  InputAdornment,
  Autocomplete,
  Box,
  Paper,
} from "@mui/material";
import { Roles, determineRoleCTYPE } from "../../../data/Utils/accessControlConst";
import resetPass from "../../../Api/Users/resetPass";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import getIks from "../../../Api/HomeIk/getIks";
import getInfoIk from "../../../Api/HomeIk/getInfoIk";
import enumRegions from "../../../Api/Regions/enumRegions";
import enumDistricts from "../../../Api/Districts/enumDistricts";
import { useSelector } from "react-redux";

const addAdminForm = ({ addUser, currentUser, statuses, updateUser }) => {
  addAdminForm.propTypes = {
    addUser: PropTypes.func,
    statuses: PropTypes.array,
  };

  const [user, setUser] = useState({
    usr_password: '',
    usr_name: '',
    usr_phone: '',
    usr_email: '',
    status_id: null,
    home_ik: 0, // Selected yik object
    isuserverified: true,
    is_banned: false,
    checkins: [],
    access_id:null,
    tag: false,
    has_access_to_ids:[],
    access_data: {
      access_id: null,
      access_level_major: null,
      access_level_minor: null
    },
    region: null,
    district: null
  });
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.user);
  const [userPwReset, setUserPwReset] = useState(false);
  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedIkForAccess, setSelectedIkForAccess] = useState([])
  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const [typeId, setTypeId] = useState(null)

  useEffect(() => {
    if (currentUser) {
      setUser((prev) => ({
        ...prev,
        ...currentUser,
        home_ik: currentUser.home_ik || prev.home_ik, // Если home_ik есть в currentUser, оставляем его
        has_access_to_ids: currentUser.has_access_to_ids || [] // Если has_access_to_ids нет, устанавливаем пустой массив
      }));
      setSelectedYik(currentUser.ik_data);

      if (currentUser.has_access_to_ids?.length > 0) {
        Promise.all(
          currentUser.has_access_to_ids.map((value) =>
            getInfoIk({ ik: { ik_id: value } })
          )
        ).then((responses) => {
          const newData = responses.reduce((acc, resp) => {
            if (resp.success) {
              acc.push(resp.data.ik);
            } else {
              alert(resp.message);
            }
            return acc;
          }, []);
          setSelectedIkForAccess(newData);
        });
      }
    }
  }, [currentUser]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeRole = (event) => {
    const { value } = event.target;
    let newAccessID = determineRoleCTYPE(value);
  
    if (newAccessID) {
      setTypeId(newAccessID[3]);
      
      // Сохраняем текущее значение home_ik и has_access_to_ids, если они существуют
      setUser((prevUserData) => ({
        ...prevUserData,
        home_ik: prevUserData.home_ik !== null ? prevUserData.home_ik : null, // Оставляем значение home_ik, если оно уже есть
        has_access_to_ids: prevUserData.has_access_to_ids.length > 0 ? prevUserData.has_access_to_ids : [], // Оставляем значение has_access_to_ids, если массив не пуст
        access_id: newAccessID[0],
        access_data: {
          access_id: newAccessID[0],
          access_level_major: newAccessID[1],
          access_level_minor: newAccessID[2],
        },
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser) {
      // Handle update
      updateUser(user);
    } else {
      // Handle add
      addUser(user);
    }
  };

  const handlePasswordReset = async () => {

    let payload = {
      "user_initiator":{
        "usr_token": token
       },
      "user":{
        "user_id":currentUser.user_id
      }
    }
    await resetPass(payload).then((resp)=> {
      if (resp.success){
        setUser((prev) => ({...prev, usr_password: resp.data.user.usr_password }))
        setUserPwReset(true)
      } else {
        alert(resp.message)
      }
    })
  };

   // Обработчик выбора значения в Autocomplete
   const handleAutocompleteChange = (event, newValue) => {
    setSelectedYik(newValue);
    setUser((prevUser) => ({
      ...prevUser,
      home_ik: newValue ? newValue.ik_id : prevUser.home_ik, // Обновляем только если выбрано новое значение
    }));
  };

const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

setSearchLoading(true);

  //Временное решение проблемы для быстрого поиска ЦИКа
  try {
    // Запрос списка yiks с сервера для текущей страницы
    const response = await getIks({
      "user_initiator": {
        "usr_token": token
      },
      "ik": {
        "type_id": typeId,
        "name": searchTerm,
      },
      //"page": currentPage
    });
    
    if(response.success){
      const yiks = response.data.iks;
      setFilteredYiks(yiks);
      setSearchLoading(false);
    }
  } catch (error) {
    console.error("Ошибка при получении списка yiks:", error);
    // Обработка ошибки, например, отображение сообщения об ошибке пользователю
    setSearchLoading(false);
  }
};

const filteredRoles = Roles.filter((role) => {
  const allowedAccess = determineRoleCTYPE(userInfo.access_data.access_id)?.[0];
  if (allowedAccess) {
    if (!currentUser && [45, 46, 47, 48].includes(role.value)) {
      return false; // Exclude roles 45, 46, 47, 48 if !currentUser
    }
    if (allowedAccess === 35){
      return role.value
    } else if (allowedAccess === 36 || allowedAccess === 37) {
      return role.value !== 36; // Exclude "Центральный супер админ"
    } else if (allowedAccess === 38) {
      return role.value === 38; // Only include "Центральный модератор"
    } else if (allowedAccess === 39 || allowedAccess === 40) {
      return role.value > 39; // Include roles starting from "Региональныq админ"
    } else if (allowedAccess === 41) {
      return role.value === 41; // Only include "Региональный модератор"
    } else if (allowedAccess === 42 || allowedAccess === 43) {
      return role.value > 42;
    } else if (allowedAccess === 44) {
      return role.value === 44; // Only include "Районный модератор"
    }
  }
  return false;
});

const handleChangeAccessIk = async (event, newValue, index) => {
  if (newValue === null) {
    return;
  }
  setUser((prevUser) => {
    const updatedHeadInIks = [...prevUser.has_access_to_ids];
    updatedHeadInIks[index] = newValue.ik_id;
    return {
      ...prevUser,
      has_access_to_ids: updatedHeadInIks,
    };
  })

  await getInfoIk({
      "ik":{
      "ik_id": newValue.ik_id
    }
    }).then((resp)=>{
      setSelectedIkForAccess((prev) => [...prev, resp.data.ik])
    })
}

const handleAddAccessToIk = () => {
  setUser((prevUser) => ({
    ...prevUser,
    has_access_to_ids: [...(prevUser.has_access_to_ids || []), 0], // Добавление значения 0, если массив пустой
  }));
};
const handleRemoveHeadInIk = (index) => {
  setUser((prevUser) => {
    const updatedHeadInIks = [...prevUser.has_access_to_ids];
    updatedHeadInIks.splice(index, 1);
    return {
        ...prevUser,
        has_access_to_ids: updatedHeadInIks,
     };
    });
    setSelectedIkForAccess((prev) => {
      const updatedSelectedIkForAccess = [...prev];
      updatedSelectedIkForAccess.splice(index, 1);
      return updatedSelectedIkForAccess;
    });
};

// БЛОК ОТВЕЧАЮЩИЙ ЗА НАЗНАЧЕНИЕ ПОЛЬЗОВАТЕЛЯ ОПРЕДЕЛЕННЫМ УРОВНЕМ ДОСТУПА

useEffect(()=> {
  if (user.access_id){
  enumRegions({}).then((response) => {
    if (response.success){
      setRegions(response.data.regions)
    } else {
      setRegions([])
      alert(response.message)
    }
  })
   }
}, [user.access_id])

useEffect(()=> {
  if (user.region !== null) {
  enumDistricts({"region":{"region_id":user.region}}).then((response) => {
    if (response.success){
      setDistricts(response.data.districts)
    } else {
      setDistricts([])
      alert(response.message)
    }
  });
  }
},[user.region])

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Почта"
        name="usr_email"
        value={user.usr_email}
        onChange={handleChange}
        margin="normal"
        //required
        fullWidth
      />
      
      <TextField
        label={currentUser ? "Новый пароль" : "Пароль"}
        name="usr_password"
        value={user.usr_password}
        onChange={handleChange}
        margin="normal"
        //required={}
        fullWidth
        type={currentUser && user.usr_password && !userPwReset ? "password" : "text"}
        InputProps={{
          endAdornment: (
            <>
            {currentUser && !userPwReset &&
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordReset} edge="end">
                <LockOpenOutlinedIcon style={{ color: 'green' }} />
                <span style={{ marginLeft: '5px', color: 'green', fontSize: 14 }}>Сбросить пароль</span>
              </IconButton>
            </InputAdornment>
             }
            </>
          ),
        }}
      />

      <TextField
        label="ФИО"
        name="usr_name"
        value={user.usr_name}
        onChange={handleChange}
        margin="normal"
        required
        fullWidth
      />
      <TextField
        label="Номер телефона"
        name="usr_phone"
        value={user.usr_phone}
        onChange={handleChange}
        margin="normal"
        //required
        fullWidth
      />

<FormControl fullWidth margin="normal">
  <InputLabel shrink={user.status_id ? true : false}>Статус</InputLabel>
  <Select 
    name="status_id" 
    label="Статус"
    value={user.status_id} 
    onChange={handleChange}
  >
    {statuses && statuses.map((status) => (
      <MenuItem key={status.id} value={status.id}>
        {status.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

      
      <FormControl fullWidth margin="normal">
      <InputLabel shrink={user.access_data.access_id ? true : false}>Роль</InputLabel>
        <Select
          name="access_id"
          label="Роль"
          value={user.access_data.access_id}
          onChange={handleChangeRole}
        >
          {filteredRoles.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


      {regions && [39, 42].includes(determineRoleCTYPE(user.access_data.access_id)?.[0]) &&
      <>
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите регион назначения супер админа</InputLabel>
        <Select
          name="region"
          value={user.region}
          onChange={e => 
            handleChange(e)
          }
        >
          {regions.map(region => (
            <MenuItem key={region.region_id} value={region.region_id}>{region.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {user.access_data.access_id === 42 &&
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите район назначения супер админа</InputLabel>
        <Select
          name="district"
          value={user.district}
          onChange={e => 
            handleChange(e)
          }
        >
          {districts.map(district => (
            <MenuItem key={district.district_id} value={district.district_id}>{district.district_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
         }
      </>
       }

      <FormControl fullWidth margin="normal">

      <Autocomplete
        //defaultValue={selectedYik}
        value={selectedYik}
        options={filteredYiks}
        loading={searchLoading}
        onChange={handleAutocompleteChange}
        onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label='Основная ИК' />}
        noOptionsText="Ничего не найдено"
        loadingText="Идет поиск. Пожалуйста, подождите!"
      />
      </FormControl>

    <InputLabel> Если администратор имеет доступ ко множеству ИК, кроме своей, то укажите эти ИК тут </InputLabel>
    <Paper>
      <Box mt={2}>
        {user.has_access_to_ids &&
          user.has_access_to_ids.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Autocomplete
                  value={selectedIkForAccess[index] || null}
                  options={filteredYiks}
                  onChange={(e, data) => handleChangeAccessIk(e, data, index)}
                  onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label='ИК' />}
                  noOptionsText="Ничего не найдено"
                  //isOptionEqualToValue={(option) => option.ik_id === item.ik_id}
                />
              </FormControl>
              <Button 
                onClick={() => handleRemoveHeadInIk(index)}
                style={{ border: '2px solid white', color: 'white' }}
              >
                - Удалить доступ
              </Button>
            </Box>
          ))}
          <Box mt={2}>
            <Button
              onClick={handleAddAccessToIk}
              style={{ border: '2px solid white', color: 'white' }}
            >
              + Предоставить доступ
            </Button>
          </Box>
      </Box>
    </Paper>

      <Button type="submit" variant="contained" color="primary" style={{marginTop: 10}} >
      {currentUser ? "Обновить" : "Добавить"}
      </Button>
    </form>
  );
};

export default addAdminForm;
