import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const addVideoLectionsForm = ({ addMaterial, editData, handleUpdate }) => {
  addVideoLectionsForm.propTypes = {
    addMaterial: PropTypes.func,
    editData: PropTypes.object,
    handleUpdate: PropTypes.func,
  };

  const userInfo = useSelector((state) => state.user.user);
  const [material, setMaterial] = React.useState({
    title: "",
    subtitle:"",
    desc:"",
    data:"",
    meta:"",
    home_ik: userInfo.home_ik,
    recom_in_iks:[],
    file_links:[],
    type_id:4
  });

  const handleChange = (e) => {
    console.log(e)
    setMaterial({ ...material, [e.target.name]: e.target.value });
  };

  const handleChangeVideo = (e) => {
    console.log(e)
    setMaterial({ ...material, file_links: [e.target.value] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editData) {
      handleUpdate(material);
    } else {
      addMaterial(material);
    }
  };

  useEffect(() => {
    if (editData) {
      setMaterial(editData);
    }
  }, [editData]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
            //autoFocus
            margin="dense"
            name="title"
            label="Название материала"
            value={material.title}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
          <TextField
            //autoFocus
            margin="dense"
            name="subtitle"
            label="Краткое описание"
            value={material.subtitle}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
          <TextField
            //autoFocus
            margin="dense"
            name="desc"
            label="Полное описание"
            value={material.desc}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
      <FormControl fullWidth margin="normal">
        {/* <input type="file" onChange={handleFileChange} /> */}
        <TextField
            //autoFocus
            margin="dense"
            name="file_links"
            label="Ссылка на ролик"
            value={material.file_links[0]}
            onChange={handleChangeVideo}
            fullWidth
            //variant="standard"
          />
      </FormControl>
      <Button type="submit" variant="contained" color="primary" margin="normal" style={{marginTop: 10}}>
          {editData ? 'Обновить' : 'Добавить'}
        </Button>
    </form>
  );
};

export default addVideoLectionsForm;