import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  useTheme
} from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import RoadmapDetails from "./roadmapDetails";
//import getInfoElections from "../../Api/Elections/getInfoElections";
//import getProtocolInfo from "../../Api/Protocols/getProtocolInfo";
//import setIsValidProtocols from "../../Api/Protocols/setIsValidProtocols";
import getListRoadmap from "../../Api/RoadMap/getListRoadmap";
import getRoadMapInfo from "../../Api/RoadMap/getRoadMapInfo";
import { dataGridStyles } from "../../styleGrid";
import { tokens } from "../../theme";
import { formatDate } from "../../data/Utils/timeZones";
import getInfoUser from "../../Api/Users/getInfoUser";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//import DataGridFilterCustom from "../../components/DataGridFilterCustom";

const roadMapList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [roadmaps, setRoadmaps] = useState([]);
  const [selectedRoadmap, setSelectedRoadmap] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);
  const [page, setPage] = useState(0);
  //const [filteredRows, setFilteredRows] = useState([]);
  let totalPages = 100000;

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "ElectionName",
      headerName: "Название выборов",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "typeElection",
      headerName: "Тип выборов",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "date",
      headerName: "Дата отправки",
      width: 150,
      renderCell: (params) => (
        formatDate(params.row.date)
      ),
    },
    {
      field: "sender",
      headerName: "Отправитель",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.author)}>
          {params.row.sender}
        </div>
      )
    },
    {
      field: "roadmapFROM",
      headerName: "ИК",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "view",
      headerName: "Действия",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleViewProtocol(params.row)}
          >
            Посмотреть
          </Button>
        </>
      ),
    },
  ];

  const fetchData = async (newPage = page) => {
    const response = await getListRoadmap({
      "user_initiator": {
        "usr_token": token
      },
      "page": newPage
    });
    if (response.success) {
      setRoadmaps(response.data.roadmaps);
    } else {
      setRoadmaps([]);
      alert(response.message);
    }
    setForce(false);
    setPage(newPage)
  };

  useEffect(() => {

    fetchData();

    const interval = setInterval(fetchData, 60 * 1000); // Обновление данных каждую минуту
    return () => clearInterval(interval);
  }, [force, token]);

  const handleViewProtocol = async (protocol) => {
    await getRoadMapInfo({
      "user_initiator":{
        "usr_token":token
      },
      "roadmap":{
        "roadmap_id":protocol.id
       }
    }).then((response)=>{
      if (response.success){
        
        const schemaParsed = JSON.parse(response.data.roadmap.data);

        setSelectedRoadmap(schemaParsed);
        setDialogOpen(true);
      } else {
        alert(response.message || response.status)
      }
    })
  };

   // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};

  const dialogProtocol = () => {
    return (
      <>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle sx={{backgroundColor: '#970D0D', color: 'white', fontSize: 17, fontWeight: 'bold'}}>Детали дорожной карты</DialogTitle>
          <DialogContent sx={{backgroundColor: 'white'}}>
            {selectedRoadmap && (
              <RoadmapDetails roadmap={selectedRoadmap} />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const rows = roadmaps.map(roadmap => {
    return {
      id: roadmap.roadmap_id,
      ElectionName: roadmap.election_data.election_name,
      typeElection: roadmap.election_data.election_type,
      date: roadmap.created_at,
      sender: roadmap.user_data.usr_name,
      //sender: roadmap.author,
      roadmapFROM: roadmap.ik_data.name,
      ...roadmap
    };
  });

  const pageChange = (newPage) => {
    fetchData(newPage)
  }

  // const filteredColumns = (columnsToFilter) => {
  //   // Исключаем колонки access_id и control
  //   return columnsToFilter.filter(column => column.field !== "access_id" && column.field !== "view");
  // };

  return (
    <Box m="20px">
      <Header title="Просмотр присланных дорожных карт" />
      <Box sx={{mt: 2}}>

       {/* <DataGridFilterCustom columns={filteredColumns(columns)} rows={rows} setFilteredRows={setFilteredRows} /> */}

       </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
        {dialogProtocol()}
        <DataGrid 
           rows={rows}
           columns={columns} 
           localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
           pageSize={100}
           paginationMode={'server'}
           rowCount={totalPages}
           onPageChange={pageChange}
        />
      </Box>
    </Box>
  );
};

export default roadMapList;
