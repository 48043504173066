import Cookies from 'js-cookie';

// Action Types
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';

// Action Creators
export const setToken = (token) => (dispatch) => {
  if (!token) {
    Cookies.remove('token');
  } else {
    Cookies.set('token', token, { expires: 3 });
  }
  dispatch({ type: SET_TOKEN, payload: token });
};

export const setUser = (user) => (dispatch) => {
  if (!user) {
    Cookies.remove('user');
  } else {
    Cookies.set('user', JSON.stringify(user), { expires: 3 });
  }
  dispatch({ type: SET_USER, payload: user });
};

export const setIsLoggedIn = (isLoggedIn) => (dispatch) => {
  Cookies.set('isLoggedIn', JSON.stringify(isLoggedIn), { expires: 3 });
  dispatch({ type: SET_IS_LOGGED_IN, payload: isLoggedIn });
};
