import { useEffect, useState } from 'react';
import { Box, Button, Dialog ,DialogContent ,DialogTitle, useTheme  } from '@mui/material';
//import { tokens } from '../../theme';
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddCandidatesForm from "./addCandidatesForm"
import addCantidate from '../../Api/Candidates/addCantidate';
import { dataGridStyles } from '../../styleGrid';
import { tokens } from '../../theme';
import getInfoCandidate from '../../Api/Candidates/getInfoCandidate';
import updateCantidate from '../../Api/Candidates/updateCandidate';
import deleteCandidate from '../../Api/Candidates/deleteCandidate';
import getCandidatesFromToken from '../../Api/Candidates/getCandidatesFromToken';
import MultiSelectWithSearch from '../../shared/CustomFilters/MultiSelectWithSearch';
import SimplePicker from '../../shared/CustomFilters/SimplePicker';
import DataGridFilterCustom from '../../components/DataGridFilterCustom';
import getParties from '../../Api/Parties/getParties';
import SearchEngine from '../../Api/SearchSystem/SearchUniversal';
import getIks from '../../Api/HomeIk/getIks';
import { useSelector } from 'react-redux';

const addCandidates = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [candidates, setCandidates] = useState([]);
  const [parties, setParties] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);

  const [isLoadingData, setIsLoadingData]= useState(false);

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const totalPages = 10000;

  //Поисковые функционал
  const [searchText, setSearchText] = useState('');
  const [debouncedText, setDebouncedText] = useState(''); // текст после задержки
  const [filters, setFilters] = useState({
    party_id: null,
    ik_id: null,
    target_el: null,
  });

  const targetElection = [
    { value: 1, label: "Глава" },
    { value: 2, label: "Депутат" },
  ];

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "candidate_name",
      headerName: "ФИО кандидата",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "ik_name",
      headerName: "Где зарегистрирован",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "target_el",
      headerName: "Должность",
      width: 150,
      renderCell: (params) => (
        params.row.target_el && params.row.target_el !== null 
            ? targetElection.find((type) => type.value === params.row.target_el).label
            : ""
      ),
    },
    {
      field: "party",
      headerName: "Партия",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      flex: 1,
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          style={{color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleEditCandidate(params.row.id)}
        >
          Редактировать
        </Button>

        <Button
          variant="outlined"
          style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleDeleteCandidate(params.row.id)}
        >
          Удалить
        </Button>
        </>
      ),
    },
  ];

  const getData = async (newPage = 0) => {
    let payload = {
      "user_initiator":{
        "usr_token": token
      },
      "candidate":{
        "target_el": filters.target_el
    },
    "party":{
        "id": filters.party_id
    },
    "ik":{
        "ik_id": filters.ik_id
    },
        "page": newPage
    }
    await getCandidatesFromToken(payload).then((response) => {
      if(response.success){
        const data = response.data.candidates.map(candidate => ({
          id: candidate.candidate_id,
          party: candidate.party_data.short_name ? candidate.party_data.short_name : 'Беспартийный',
          ik_name: candidate.ik_data.name,
          ...candidate
        }));

        setCandidates(data);
        setForce(false);
      } else {
        setForce(false)
        setCandidates([]);
        alert(response.message)
      }
    });
  }

  const getPartiesData = async () => {
    await getParties({}).then((resp) => {
       if (resp.success) {
         const dataFormat = resp.data.parties.map((party) => ({
           value: party.id,
           label: party.short_name,
         }));
         setParties(dataFormat)
       } else {
         setParties([])
         alert(resp.message)
       }
     })
   }

  useEffect(() => {
    if (force)
      getData();
      getPartiesData();
  }, [force]);

  useEffect(()=> {
    if(filters.party_id || filters.ik_id || filters.target_el){
      getData();
    } else if (filters.party_id === null && filters.ik_id === null && filters.target_el === null){
      getData();
    }

  },[filters.party_id, filters.ik_id, filters.target_el ])

   // Используем useEffect для debounce
   useEffect(() => {
    // Создаем таймер для debounce
    const handler = setTimeout(() => {
      setDebouncedText(searchText); // Устанавливаем значение после задержки
    }, 300); // Устанавливаем задержку в миллисекундах (например, 300 мс)

    // Очищаем таймер при каждом изменении searchText
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText !== '') {
      handleSearch();
    }
  }, [debouncedText]);


  const handleEditCandidate = async (candidateId) => {
    let payload = {
      "candidate": {
        "candidate_id": candidateId
     } 
    }

    await getInfoCandidate(payload).then((resp) => {
      if (resp.success){
        setSelectedCandidate(resp.data.candidate);
        handleClickOpen();
      } else {
        alert(resp.message)
      }
    })
  };

  const addCandidate = async (candidate) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
    },
       "candidate": candidate
    }
      await addCantidate(payload).then((resp) => {
         if (resp.success){
          alert(resp.data.sys_msg)
          setForce(true);
          setOpen(false);
         } else {
           alert(resp.message)
         }
      })
  };

  const updateCandidateData = async (candidate) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
    },
       "candidate": candidate
    }
      await updateCantidate(payload).then((resp) => {
         if (resp.success){
          alert(resp.data.sys_msg)
          setForce(true);
          setOpen(false);
         } else {
           alert(resp.message)
         }
      })
  };

  const handleDeleteCandidate = async (id) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
    },
       "candidate": {
        "candidate_id": id
       }
    }
      await deleteCandidate(payload).then((resp) => {
         if (resp.success){
          alert(resp.data.sys_msg)
          setForce(true);
          setOpen(false);
         } else {
           alert(resp.message)
         }
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCandidate(null);
  };

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить кандидата
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Укажите данные о кандидате"}
        </DialogTitle>
        <DialogContent>
           <AddCandidatesForm addCandidate={addCandidate} selectedCandidate={selectedCandidate} updateCandidate={updateCandidateData}/>
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  //ПОИСК

  // Поисковый функционал
  const onClearSearch = () => {
    setSearchText('')
    setCandidates([]);
    setForce(true);
}

const handleSearch = async () => {

  let payload = {
    "user_initiator": {
        "usr_token": token
    },
    "candidate":{
        "candidate_name": searchText,
        "target_el": filters.target_el
    },
    "party":{
        "id": filters.party_id
    },
    "ik":{
        "ik_id": filters.ik_id
    }
}

  if (debouncedText.length > 2){
    setIsLoadingData(true);

    await SearchEngine(payload, "candidates").then((resp) => {
      if (resp.success){
        const data = resp.data.candidates.map(candidate => ({
          id: candidate.candidate_id,
          party: candidate.party_data.short_name ? candidate.party_data.short_name : 'Беспартийный',
          ik_name: candidate.ik_data.name,
          ...candidate
        }));
        setCandidates(data);
        setIsLoadingData(false);
      } else {
        alert(resp.message)
        setIsLoadingData(false);
      }
    })
  } else if (debouncedText.length == 0 && debouncedText.length == ""){
     setCandidates([]);
     setForce(true)
  }
}


// ФИЛЬТРЫ

const [multiSelectOptions, setMultiSelectOptions] = useState([]); // Опции для многовыборного фильтра
  const [selectedMultiOptions, setSelectedMultiOptions] = useState(null); // Выбранные опции для многовыборного фильтра

  const handleSearchIk = async (event, newValue, reason) => {

      if (reason === 'reset') {
        setMultiSelectOptions([]);
        setSelectedMultiOptions(null);
        return
      }
    
      if (event?.type === 'click' || event === null || event?.target === null) {
        return;
       }
    
       const searchTerm = event.target.value;
    
      if (searchTerm.length <= 2) {
        return;
      }
    
    //setSearchLoading(true);
    
     //Временное решение проблемы для быстрого поиска ЦИКа
     try {
       // Запрос списка yiks с сервера для текущей страницы
       const response = await getIks({
         "user_initiator": {
           "usr_token": token
         },
         "ik": {
           "name": searchTerm,
         },
         //"page": currentPage
       });
       
       if(response.success){
         const yiks = response.data.iks;
         setMultiSelectOptions(yiks);
         //setSearchLoading(false);
       }
     } catch (error) {
       console.error("Ошибка при получении списка yiks:", error);
       // Обработка ошибки, например, отображение сообщения об ошибке пользователю
       //setSearchLoading(false);
     }
    };

  const multiSetFilters = (newValue) => {
    setSelectedMultiOptions(newValue)
    if(newValue !== null){
      setFilters({ ...filters, ik_id: newValue.ik_id })
    } else {
      setFilters({ ...filters, ik_id: null})
    }
  }


  const FiltersElements = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', ml: 2, mr: 2, mb: 2, justifyContent: 'center', gap: 2 }}> {/* Добавлен отступ с помощью gap */}
            {/* Фильтр Типа 1: Выбор из множества данных с встроенным поиском */}
            <SimplePicker
                options={parties}
                selectedOption={filters.party_id}
                setSelectedOption={(value) => setFilters({ ...filters, party_id: value })}
                label="Фильтр по партиям"
            />

           <SimplePicker
                options={targetElection}
                selectedOption={filters.target_el}
                setSelectedOption={(value) => setFilters({ ...filters, target_el: value })}
                label="Фильтр по должности"
            />

            <MultiSelectWithSearch
                options={multiSelectOptions}
                selectedOptions={selectedMultiOptions}
                setSelectedOptions={multiSetFilters}
                label="Фильтр по ИК"
                onSearch={handleSearchIk}
            />
            
        </Box>
    );
}

  const pageChange = (newPage)=>{
    getData(newPage);
  }

  return (
    <Box m="20px">
    <Header title="Добавление кандидатов" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
        <DataGridFilterCustom additionalButton={handleOpenAddDialog()} searchText={searchText} setSearchText={setSearchText} onClearSearch={onClearSearch} filtersButtons={FiltersElements()}/>

       <DataGrid 
       rows={candidates} 
       columns={columns} 
      //  components={{ Toolbar: handleOpenAddDialog  }} 
       loading={isLoadingData}
       localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} 
       pageSize={100}
       paginationMode={'server'}
       rowCount={totalPages}
       onPageChange={pageChange}
       />
    </Box>
    </Box>
  );
};

export default addCandidates;