import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { userReducer } from '../Reducers/userReducer';

// Combine reducers (if you have more, add them here)
const rootReducer = combineReducers({
  user: userReducer,
});

// Create store with thunk middleware
export const store = createStore(rootReducer, applyMiddleware(thunk));
