import { useState, useEffect } from "react";
import { Routes, Route , useNavigate  } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
// import Form from "./scenes/form";
// import FAQ from "./scenes/faq";
import Login from "./scenes/login";
import AddAdmins from "./scenes/addAdmins"
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import AddUsers from "./scenes/addUsers";
import AddRegions from "./scenes/addRegions";
import AddDistrict from "./scenes/addDistrict"
import AddElections from "./scenes/addElections"
import ObserverHandBook from "./scenes/education/observerHandBook"
import Memos from "./scenes/education/memos"
import BriefMemos from "./scenes/education/briefMemos"
import VideoLections from "./scenes/education/videoLections";
import MapViolations from "./scenes/mapViolations"
import RoadMap from "./scenes/roadMap"
import RoadMapList from "./scenes/roadMapList"
import Protocols from "./scenes/protocols"
// import AddYiks from "./scenes/addYiks"
import AddIks from "./scenes/addIks"
import AddStatuses from './scenes/addStatuses'
import AddCandidates from "./scenes/addCandidates"
import AddParties from "./scenes/addParties"
import ProtocolsMap from "./scenes/protocolMap"
import ProtocolList from "./scenes/sends/protocolList"
import InvalidProtocols from './scenes/invalidProtocols'
import AchiveIncendents from "./scenes/achiveIncendents";
import UsersWorks from "./scenes/usersWorks";
import PresenceMap from "./scenes/PresenseMap";
import Profile from "./scenes/profile";
import SampleСomplaints from "./scenes/education/SampleСomplaints";
import LawsAndRegulations from "./scenes/education/LawsAndRegulations";
import getInfoUser from "./Api/Users/getInfoUser";
//import NotFound from "./scenes/notFound";
import StatisticsPage from "./scenes/statistics";
import InvalidMapViolations from "./scenes/InvalidMapViolations";
import PublishMapOfViolations from "./scenes/publishMapOfViolations";
import NotificationPage from "./scenes/notificationPage";
import Pfields from "./scenes/Pfields";
import getMyElections from "./Api/Users/myElections";
//import PrivateRoute from "./components/PrivateRoutes";
import { setToken, setUser } from './stores/Actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import CommissionProfile from "./scenes/commisionProfile";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

//   useEffect(() => {
//     if (token === null ) setToken(undefined); //navigate('/login');
//     if (userInfo === null) setUser(null);

//      console.log("INFO USER:",userInfo)
//      console.log("TOKEN USER:",token)
    
// }, [token, navigate, userInfo]);

// useEffect(() => {
//   if (!token && isLoggedIn) navigate('/');

//   // console.log("INFO USER:", userInfo);
//   // console.log("TOKEN USER:", token);
// }, [token, navigate, userInfo]);

const logoutToken = () => {
  navigate('/');
  dispatch(setToken(null));
  dispatch(setUser(null));
};

useEffect(() => {
  if(token && navigate && isLoggedIn) updateUserData();
}, [navigate, token ]);


const updateUserData = async () => {
  if (!token && !isLoggedIn) {
    return;
  }

  try {
    const resp = await getInfoUser({
      "user_initiator": {
        "usr_token": token
      }
    });

    if (resp.success) {
      const electionResponse = await getMyElections({
        "user_initiator": { "usr_token": token }
      });

      if (electionResponse.success) {
        const updatedUserData = {
          ...userInfo,
          ...resp.data.user,
          election_user: [...electionResponse.data.elections],
        };

        dispatch(setUser(updatedUserData));
        
      } else {
        console.error('Error fetching election data:', electionResponse);
      }
    } else if (resp.code && resp.code === 403) {
      alert('Был выполнен вход через админ панель. Пожалуйста, авторизуйтесь снова');
      logoutToken();
    } 
  } catch (error) {
    console.error("An error occurred during user data update:", error);
  }
};

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* Conditionally render the routes based on isLoggedIn state */}
            <>
            {token !== null && <Sidebar isSidebar={isSidebar} /> }
              <main className="content">
              {/* {token !== undefined && <Topbar setIsSidebar={setIsSidebar} handleLogout={logoutToken} /> } */}
              <Topbar setIsSidebar={setIsSidebar} handleLogout={logoutToken} />
               <Routes>
                 {token === null ? (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/" element={<StatisticsPage />} />
                    </>
                 ) : (
                    <>
                      <Route path="/" element={<StatisticsPage />} />
                      {/* <Route path="*" element={<NotFound />} /> */}
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/admins" element={<AddAdmins />} />
                      <Route path="/users" element={<AddUsers />} />
                      <Route path="/regions" element={<AddRegions />} />
                      <Route path="/districts" element={<AddDistrict />} />
                      <Route path="/elections" element={<AddElections />} />
                      {/* <Route path="/yiks" element={<AddYiks />} /> */}
                      <Route path="/iks" element={<AddIks />} />
                      <Route path="/candidates" element={<AddCandidates />} />
                      <Route path="/parties" element={<AddParties />} />
                      <Route path="/statuses" element={<AddStatuses />} />
                      <Route path="/observerHandbook" element={<ObserverHandBook />} />
                      <Route path="/memos" element={<Memos />} />
                      <Route path="/briefMemos" element={<BriefMemos />} />
                      <Route path="/videoLections" element={<VideoLections />} />
                      <Route path="/sampleComplaints" element={<SampleСomplaints />} />
                      <Route path="/lawsAndRegulations" element={<LawsAndRegulations />} />
                      <Route path="/violations" element={<MapViolations />} />
                      <Route path="/violationsInvalid" element={<InvalidMapViolations />} />
                      <Route path="/violationsPublish" element={<PublishMapOfViolations />} />
                      <Route path="/roadmaps" element={<RoadMap />} />
                      <Route path="/roadmapList" element={<RoadMapList />} />
                      <Route path="/protocols" element={<Protocols />} />
                      <Route path="/protocolsMap" element={<ProtocolsMap />} />
                      <Route path="/sendProtocol" element={<ProtocolList />} />
                      <Route path="/invalidProtocols" element={<InvalidProtocols />} />
                      <Route path="/archiveIncendents" element={<AchiveIncendents />} />
                      <Route path="/usersWork" element={<UsersWorks />} />
                      <Route path="/presenceStat" element={<PresenceMap />} />
                      <Route path="/me" element={<Profile />} />
                      <Route path="/pfields" element={<Pfields />} />
                      <Route path="/commisionProfile" element={<CommissionProfile />} />

                      <Route path="/sendNotifications" element={<NotificationPage />} />
                    </>
                  )}
              </Routes>
              </main>
            </>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;