import { 
    Box,
    Button,
    //Grid,
    IconButton,
    InputLabel,
    MenuItem,
     //Box 
     Select,
    } from '@mui/material';
//import SquareStat from '../../../shared/UI/SquareStat';
import { useEffect, useState } from 'react';
import MessageItem from './MessageItem';
import FilterComponent from '../../../shared/FilterComponent';
import enumRegions from '../../../Api/Regions/enumRegions';
import enumDistricts from '../../../Api/Districts/enumDistricts';
import getElections from '../../../Api/Elections/getElections';
//import getElectionsType from '../../../Api/Elections/getElectionsType';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import Map from './Map';
import "leaflet/dist/leaflet.css";
import getPublicList from '../../../Api/incidents/getPublicList';
import PropTypes from "prop-types";
import { typesEl } from '../../../data/Utils/types';
import { formatDate, formatDateWithoutTime } from '../../../data/Utils/timeZones';

export default function StatViolations({selectedTab}) {
  StatViolations.propTypes = {
    selectedTab: PropTypes.number,
  };
      const [data, setData] = useState([]);
      const [regions, setRegions] = useState([]);
      const [districts, setDistricts] = useState([]);
      const [electionsTypes, setElectionsTypes] = useState([]);
      const [elections, setElections] = useState([]);
      const [filteredRows, setFilteredRows] = useState([]);
      const [searchText, setSearchText] = useState('');
      const [force, setForce] = useState(true);

      const [page, setPage] = useState(0); // текущая страница
      const [hasMorePages, setHasMorePages] = useState(true); // для проверки наличия следующей страницы

      const [filters, setFilters] = useState({
        reg_id: null,
        district_id: null,
        electionType: null,
        election_id: null,
      })

      //console.log("elections ", elections)

      const [markers, setMarkers] = useState(null);

      // Запрос данных с учетом пагинации
  useEffect(() => {
    let payload = {
      election: {
        election_id: filters.reg_id,
      },
      ik: {
        ik_id: null,
        region_id: filters.reg_id,
        district_id: filters.district_id,
      },
      page: page, // передача номера страницы
    };

    if (force && selectedTab === 1) {
      getPublicList(payload).then((resp) => {
        if (resp.success) {
          let newData = [];
          let newMarkers = [];

          resp.data.incidents.forEach((incident) => {
            const newDataObj = {
              id: incident.incident_id,
              title: incident.incident_title,
              address: incident.ik_data.name,
              userName: incident.user_data.usr_name,
              role: incident.user_data.status_data.name,
              election_type: typesEl.find(index => index.value === incident.election_data.election_type)?.label,
              election_name: incident.election_data.election_name,
              time: formatDate(incident.created_at),
              starts_at_el: formatDateWithoutTime(incident.election_data.starts_at),
              ends_at_el: formatDateWithoutTime(incident.election_data.ends_at),
              avatar: null,
              text: incident.incident_data,
              file_links: incident.file_links,
            };
            newData.push(newDataObj);

            const lat = parseFloat(incident.ik_data.lat);
            const lng = parseFloat(incident.ik_data.long);
            const isLatValid = !isNaN(lat);
            const isLngValid = !isNaN(lng);

            const marker = {
              id: incident.incident_id,
              lat: isLatValid ? lat : 55.752548,
              lng: isLngValid ? lng : 37.622057,
              popupContent: incident.ik_data.name,
            };
            newMarkers.push(marker);
          });

          setData(newData);
          setMarkers(newMarkers);
          setForce(false);

          // Проверка на наличие следующей страницы
          setHasMorePages(newData.length === 100); // если получено 100 элементов, следующая страница существует
        }
      });
    }
  }, [force, selectedTab, page]);

  // Функция для перехода на следующую страницу
  const handleNextPage = () => {
    if (hasMorePages) {
      setPage((prevPage) => prevPage + 1);
      setForce(true);
    }
  };

  // Функция для перехода на предыдущую страницу
  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
      setForce(true);
    }
  };

    useEffect(() => {
      enumRegions({}).then((resp) => {
        if(resp.success) setRegions(resp.data.regions)
        else alert(resp.message)
      })

      // getElectionsType({}).then((resp) => {
      //   if(resp.success) setElectionsTypes(resp.data.election_types)
      //   else alert(resp.message)
      // })

      // getElections({}).then((resp) => {
      //     if(resp.success) setElections(resp.data.elections)
      //     else alert(resp.message)
      //   })

      setElectionsTypes(typesEl)
    },[])

    useEffect(() => {
      let payload = {
        "region":{
          "region_id":filters.reg_id
        }
      }
        if(filters.reg_id){
        enumDistricts(payload).then((resp) => {
            if(resp.success) setDistricts(resp.data.districts)
            else alert(resp.message)
          })
        }

    },[filters.reg_id])

    useEffect(() => {
      if(filters.electionType){
        let payload = {
            "election":{
                "election_type": filters.electionType
            }
        }
      
        getElections(payload).then((resp) => {
            if(resp.success) setElections(resp.data.elections)
            else alert(resp.message)
        })
      }
     
    },[filters.electionType])

    const handleChangeFilter = (e, type, data) => {
        if(type){
           setFilters( (prev) => ({...prev, [type]: data }) );
        }else {
          setFilters( (prev) => ({...prev, [e.target.name]: e.target.value }) );
        }
    }
    
    const filtersButtons = () => {
      return (
        <>
      <Box sx={{flexDirection: 'column', mt: 1}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Регион:</InputLabel>
            <Select 
                value={filters.reg_id} 
                name='reg_id' 
                style={{height: 30, border: '1px solid black'}} 
                sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
                onChange={ (e) => handleChangeFilter(e) }
                IconComponent={() => (
              <>
              {filters.reg_id && (
               <IconButton size="small" onClick={(e) => handleChangeFilter(e, 'reg_id', null)}> {/* Добавляем IconButton для очистки */}
                 <ClearSharpIcon style={{color: 'black'}}/>
               </IconButton>
               )}
               </>
           )
           }
            >
               {regions && regions.map((region) => (
                  <MenuItem key={region.region_id} value={region.region_id}>
                    {region.name}
                  </MenuItem>
                ))}
            </Select>
        </Box>

          <Box sx={{flexDirection: 'column', mt: 1}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Район:</InputLabel>
            <Select 
            value={filters.district_id} 
            name='district_id' 
            style={{height: 30, border: '1px solid black'}} 
            sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
            onChange={(e) => handleChangeFilter(e) }
            IconComponent={() => (
              <>
              {filters.district_id && (
               <IconButton size="small" onClick={(e) => handleChangeFilter(e, 'district_id', null)}> {/* Добавляем IconButton для очистки */}
                 <ClearSharpIcon style={{color: 'black'}}/>
               </IconButton>
               )}
               </>
           )
           }
            >
               {districts && districts.map((district) => (
                  <MenuItem key={district.district_id} value={district.district_id}>
                    {district.district_name}
                  </MenuItem>
                ))}
            </Select>
            </Box>

            <Box sx={{flexDirection: 'column', mt: 1}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Тип выборов:</InputLabel>
            <Select 
                value={filters.electionType} 
                name='electionType' 
                style={{height: 30, border: '1px solid black'}} 
                sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
                onChange={(e) => handleChangeFilter(e)}
                IconComponent={() => (
                  <>
                  {filters.electionType && (
                   <IconButton size="small" onClick={(e) => handleChangeFilter(e, 'electionType', null)}> {/* Добавляем IconButton для очистки */}
                     <ClearSharpIcon style={{color: 'black'}}/>
                   </IconButton>
                   )}
                   </>
               )
               }
                >
               {electionsTypes && electionsTypes.map((electionType, index) => (
                  <MenuItem key={index} value={electionType.name}>
                    {electionType.name}
                  </MenuItem>
                ))}
            </Select>
            </Box>

            <Box sx={{flexDirection: 'column', mt: 1}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Выборы:</InputLabel>
            <Select 
               value={filters.election_id} 
               name='election_id' 
               style={{height: 30, border: '1px solid black'}} 
               sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
               onChange={(e) => handleChangeFilter(e)}
               IconComponent={() => (
                    <>
                    {filters.election_id && (
                     <IconButton size="small" onClick={(e) => handleChangeFilter(e, 'election_id', null)}> {/* Добавляем IconButton для очистки */}
                       <ClearSharpIcon style={{color: 'black'}}/>
                     </IconButton>
                     )}
                     </>
                 )
                }
              >
               {elections && elections.map((election) => (
                  <MenuItem key={election.election_id} value={election.election_id}>
                    {election.election_name}
                  </MenuItem>
                ))}
            </Select>
            </Box>
            </>
      ) 
    }

    const [showFilters, setShowFilters] = useState(false); // Состояние для отображения/скрытия фильтров

  // Функция для изменения состояния отображения фильтров
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  
  const onClearSearch = () => {
    setSearchText('')
    setFilteredRows(data);
 }

 // Фильтрация данных при изменении текста поиска
 useEffect(() => {
  const filtered = data.filter(item =>
    item.text.toLowerCase().includes(searchText.toLowerCase()) // Измените на ваше поле данных, по которому вы хотите производить поиск
  );
  setFilteredRows(filtered);
}, [searchText]);
 

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
        <Box display={'flex'} width='50%' justifyContent={'center'} marginBottom={4}>
           <FilterComponent 
           placeholderSearch='Поиск по тексту сообщения'
           isSearch
           styleSearch={{ width: '100%' }}
           onChange={(e) => setSearchText(e.target.value)}
                //onClickSearch={handleSearch}
           onClickSearchClean={onClearSearch}
           searchString={searchText}
           styleContainer={{height: !showFilters ? '25%' : '50%' }}
           >
           {!showFilters && (
               <Button variant="outlined" onClick={toggleFilters} sx={{mt: 2}} style={{border: '1px solid black', borderColor: 'red', color: 'black',fontSize: 14, fontWeight: '600'}} >
                   Открыть фильтры
               </Button>
           )}

      {/* Фильтры */}
      {showFilters && (
        <Box display='flex' width={'100%'} flexDirection='column' alignItems='flex-start' justifyContent={'flex-start'} margin={5}>
             {filtersButtons()}
          {/* Кнопка "Скрыть фильтры", отображается если фильтры открыты */}
          <Button variant="outlined" onClick={toggleFilters} sx={{mt: 2}} style={{border: '1px solid black', borderColor: 'red', color: 'black',fontSize: 14, fontWeight: '600'}}>
            Скрыть фильтры
          </Button>
          </Box>
         )}
            {/* <Box sx={{marginTop: 10}}>
              {filtersButtons()}
              </Box> */}
           </FilterComponent>
        </Box>

        <Box sx={{ display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center', marginBottom: 2, boxShadow: "rgb(145 158 171 / 50%) 0px 0px 2px 0px, rgb(145 158 171 / 50%) 0px 16px 32px -4px", }}>
           {markers !== null &&
           <Map markers={markers} />
           }
        </Box>

        {/* Incidents List */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', marginBottom: 2 }}>
        {filteredRows.length > 0
          ? filteredRows.map((squareData) => <MessageItem key={squareData.id} data={squareData} />)
          : data.map((squareData) => <MessageItem key={squareData.id} data={squareData} />)}
      </Box>

       {/* Pagination Controls */}
<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
  <Button
    onClick={handlePreviousPage}
    disabled={page === 0}
    style={{
      border: '1px solid',
      borderColor: page === 0 ? 'gray' : 'red',
      color: page === 0 ? 'gray' : 'black',
      fontSize: 14,
      fontWeight: '600',
      textTransform: 'capitalize', // для текста с заглавной буквы
      marginRight: '10px', // отступ между кнопками
    }}
  >
    Предыдущая
  </Button>
  
  <Button
    onClick={handleNextPage}
    disabled={!hasMorePages}
    style={{
      border: '1px solid',
      borderColor: !hasMorePages ? 'gray' : 'red',
      color: !hasMorePages ? 'gray' : 'black',
      fontSize: 14,
      fontWeight: '600',
      textTransform: 'capitalize', // для текста с заглавной буквы
    }}
  >
    Следующая
  </Button>
</Box>

      </Box>
    );
  }