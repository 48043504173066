import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";
import L from 'leaflet'  //{ MarkerCluster }
//import MarkerCluster from './MarkerCluster';
import MarkerClusterGroup from 'react-leaflet-cluster'
import mapIconUrl from '../../../Icons/MapIcon2.png';

const Map = ({ markers }) => {
    Map.propTypes = {
        markers: PropTypes.array,
      };

    const HideAttributionControl = () => {
        const map = useMap();
        map.attributionControl.setPrefix('');
        return null;
      };

      const customIcon = new L.Icon({
        iconUrl: mapIconUrl,
        iconSize: new L.Point(60, 67),
      })

      const createClusterCustomIcon = function (cluster) {
        return L.divIcon({
          iconSize: new L.Point(60, 67),
          className: 'iconCluster',
          html: `<div style="position: relative; width: 60px; height: 67px;">
                    <img src="${customIcon.options.iconUrl}" style="width: 100%; height: 100%;" />
                    <span style="position: absolute; top: 20%; left: 50%; transform: translate(-50%, -50%); color: white; font-weight: bold; font-size: 14px;">${cluster.getChildCount()}</span>
                </div>`,
        });
      };

  return (
    <>
    <MapContainer center={[55.755811, 37.617617]} zoom={5} scrollWheelZoom={false} whenAttributionControl={false} style={{width: '100%', height: '400px',}}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <HideAttributionControl />
      <MarkerClusterGroup
          onClick={(e) => console.log('onClick', e)}
          iconCreateFunction={createClusterCustomIcon}
          maxClusterRadius={150}
          spiderfyOnMaxZoom={true}
          showCoverageOnHover={false}
        >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={[marker.lat, marker.lng]}
            title={marker.popupContent}
            icon={customIcon}
          ></Marker>
        ))}
        </MarkerClusterGroup>
    </MapContainer>
    </>
  );
};

export default Map;