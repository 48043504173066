import { useState, useEffect  } from "react";
import { Box, Button, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
//import { tokens } from "../../theme";
import Header from "../../components/Header";
import Square from "../../shared/UI/SquareData"; // Путь к вашему Square компоненту
import getNode from "../../Api/PCount/getNode";
import getNodeAllReports from "../../Api/PCount/getNodeAllReports";
import { dataGridStyles } from "../../styleGrid";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import { ArrowBack } from "@mui/icons-material";
import LoaderSpin from "../../shared/Loader/CustomLoader";
import { formatDate } from "../../data/Utils/timeZones";
import getInfoUser from "../../Api/Users/getInfoUser";
import { useNavigate } from "react-router-dom";
//import FilterComponent from "../../shared/FilterComponent";
import getIntervalsElections from "../../Api/Elections/getIntervalsElections";
//import getElections from "../../Api/Elections/getElections";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import DataGridFilterCustom from "../../components/DataGridFilterCustom";
import { useSelector } from "react-redux";

const PresenceMap = () => {
  const navigate = useNavigate();
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.user);
  
  const [data, setData] = useState([]); // Ваши данные приходят сюда
  const [ikNow, setIkNow] = useState([]);
  const [countIk, setCountIk] = useState([]);
  const [isReports, setIsReports] = useState(false);
  const [isBackButton, setIsBackButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [lastVisitedId, setLastVisitedId] = useState(null);
  const [iksFromAccess, setIksFromAccess] = useState(null);
  // const [history, setHistory] = useState([userInfo.home_ik]);
  // const [historySub, setHistorySub] = useState([]);
  //const subNav = false;
  // const [subNav, setSubNav] = useState(false);
  // const lastVisitedId = history[history.length - 1];
  // const lastVisitedSub = historySub[historySub.length - 1];

  const [navigationHistory, setNavigationHistory] = useState([{ ik_id: userInfo.home_ik, sub_id: null }]);

  // Применяется для фильтров
  const [electionsTimes, setElectionsTimes] = useState([]);
  // const elections = userInfo.election_user;
  // const [selectedElectionId, setSelectedElectionId] = useState(userInfo.election_user.length > 1 ? null : userInfo.election_user[0].election_id);
  const [selectedElectionId, setSelectedElectionId] = useState(
    userInfo?.election_user && userInfo.election_user.length > 1
      ? null
      : userInfo?.election_user?.[0]?.election_id || null
  );
  const [selectedTimeInterval, setSelectedTimeInterval] = useState(null);
  const [page, setPage] = useState(0);
  let totalPages = 100000;

  let currentTime = new Date();
  var timeString = currentTime.toTimeString();

  // const isFocused = useRef(true); // Реф для отслеживания фокуса страницы

  
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "author",
      headerName: "Автор",
      flex: 1,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.author_id)}>
          {params.row.author}
        </div>
      )
    },
    {
      field: "ik",
      headerName: "ИК",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "elections",
      headerName: "Выборы",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "time",
      headerName: "Время",
      flex: 1,
      renderCell: (params) => (
        formatDate(params.row.time)
      ),
    },
    {
      field: "notes",
      headerName: "Дополнительные сведения",
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  // const handleSquareClick = (ikId, isSubsNav, subId) => {
  //   console.log('TEST TEST', isSubsNav, ikId, subId)
  //   if (isSubsNav) {
  //     setHistorySub(prevHistory => {
  //       if (!prevHistory.includes(subId)) {
  //         return [...prevHistory, subId];
  //       }
  //       return prevHistory;
  //     });
  //     setSubNav(true)

  //   } else {
  //     setHistory(prevHistory => {
  //       if (!prevHistory.includes(ikId)) {
  //         return [...prevHistory, ikId];
  //       }
  //       return prevHistory;
  //     });
  //   }

  const handleSquareClick = (ikId, isSubsNav, subId) => {
    // const electionHomeIk = elections.find((election) => election.election_id === selectedElectionId)?.home_ik;  
    // if(electionHomeIk !== ikId) {
    //   return alert('Эта избирательная комиссия не участвует в выбранных выборах.');
    // }

    setNavigationHistory(prevHistory => {
      const newEntry = {
        ik_id: isSubsNav ? prevHistory[prevHistory.length - 1].ik_id : ikId,
        sub_id: isSubsNav ? subId : prevHistory[prevHistory.length - 1].sub_id,
      };
      return [...prevHistory, newEntry];
    });
  };
    // setHistory(prevHistory => {
    //   if (!prevHistory.includes(ikId)) {
    //     return [...prevHistory, ikId];
    //   }
    //   return prevHistory;
    // });
  // };

  

   // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};

// Запрос всех выборов и временных интервалов
useEffect(() => {
  const fetchAllData = async () => {
    let payload = {
      "election":{
        "election_id": selectedElectionId
     }
    }
    try {
      const intervalsResp = await getIntervalsElections(payload);
      if (intervalsResp.success) {
        setElectionsTimes(intervalsResp.data.elections.map(({ election_id, pcount_deltas }) => ({
          election_id,
          pcount_deltas
        })));
      } else {
        alert(intervalsResp.message);
      }
      
      // const electionsResp = await getElections({});
      // if (electionsResp.success) {
      //   setElections(electionsResp.data.elections);
      // } else {
      //   alert(electionsResp.message);
      // }

    } catch (error) {
      alert(error.message);
    }
  };

  if(selectedElectionId !== null) fetchAllData();
}, [selectedElectionId]);

const fetchData = async ( newPage = page ) => {
  setIsLoading(true);
  const { ik_id, sub_id } = navigationHistory[navigationHistory.length - 1];

  // if (ik_id === null || ik_id === undefined) {
  //   setIsLoading(false);
  //   return alert('На текущем ИК нет явки. Попробуйте позже');
  // }
  try {
    const resp = await getNode({
      "user_initiator": {
        "usr_token": token
      },
      "pcount": {
        "ref_time_id": selectedTimeInterval || 0,
        "election_id": selectedElectionId,
        "ik_id": ik_id,
        "sub_id": sub_id
      }
    });
    if (resp.success) {
      // if(resp.data.pcount.downstream_data && resp.data.pcount.downstream_data.length === 0){
      //   setSelectedTimeInterval(null);
      //   return
      // }
      if (resp.data.pcount.downstream_iks && resp.data.pcount.downstream_iks.length > 0) {
        const newData = resp.data.pcount.downstream_data.map((pcount) => {
            // Проверяем наличие pcount_std в ik_data или el_subdivision
            const pcountStd = (pcount.ik_data && pcount.ik_data.pcount_std) || 
            (pcount.el_subdivision && pcount.el_subdivision.pcount_std) || 0;
            return {
                id: pcount.ik_data.ik_id ? pcount.ik_data.ik_id : pcount.el_subdivision.sel_id,
                is_subs_nav: pcount.ik_data.ik_id ? false : true,
                sub_id: pcount.sub_id,
                title: pcount.ik_data.ik_id ? pcount.ik_data.name : pcount.el_subdivision.name,
                count: pcount?.true_count || 0,
                // Если есть pcount_std в ik_data или el_subdivision, то используем его для расчета процента
                percent: pcountStd ? `${ pcount.true_count ? parseFloat(((pcount?.true_count / pcountStd) * 100).toFixed(3)) : 0 } %` : '0%',
                total: pcount.ik_data.ik_id ? pcount.ik_data.pcount_std : pcount.el_subdivision.pcount_std || 0
            };
        });
        if(resp.data.pcount.downstream_data && resp.data.pcount.downstream_data.length === 0){
          //setSelectedTimeInterval(null);
          return setSelectedTimeInterval(null);
        }
        setData(newData);
        setIkNow( resp.data.pcount.ik_data.name ? resp.data.pcount.ik_data.name : resp.data.pcount.el_subdivision.name);
        setCountIk(resp.data.pcount?.true_count || 0);
        setIsBackButton(ik_id !== userInfo.home_ik && ik_id !== iksFromAccess);
      } else {
        const respReports = await getNodeAllReports({
          "pcount": {
            "ref_time_id": selectedTimeInterval,
            "election_id": selectedElectionId,
            "ik_id": ik_id
          },
          "page": newPage
        });
        if (respReports.success) {
          if (respReports.data.pcounts.length > 0) {
            const newData = respReports.data.pcounts.map((pcount) => ({
              id: pcount.id,
              author: pcount.user_data.usr_name,
              ik: pcount.ik_data.name,
              time: pcount.created_at,
              elections: pcount.election_data.election_name,
              notes: pcount.notes,
              ...pcount
            }));
            setData(newData);
            setIkNow("Список отправленных явок на данном УИК");
            setCountIk('');
            setIsReports(true);
            setIsBackButton(ik_id !== userInfo.home_ik && ik_id !== iksFromAccess);
          } else {
            alert('На текущей ИК нет явки или не настроены выборы должным образом');
            return
          }
        } else {
          alert(respReports.message);
        }
        setPage(newPage)
      }
    } else {
      //alert(resp.message);
    }
  } catch (error) {
    alert(error.message);
  } finally {
    setIsLoading(false);
  }
};

// useEffect(() => {
//   const onFocus = () => {
//     isFocused.current = true;
//     fetchData(); // Обновляем данные при возвращении фокуса
//   };

//   const onBlur = () => {
//     isFocused.current = false;
//   };

//   window.addEventListener('focus', onFocus);
//   window.addEventListener('blur', onBlur);

//   fetchData(); // Вызываем fetchData при первом монтировании

//   return () => {
//     window.removeEventListener('focus', onFocus);
//     window.removeEventListener('blur', onBlur);
//   };
// }, []);

// Получаем данные
useEffect(() => {
  if (userInfo.home_ik !== null) fetchData(); // Обновляем данные при изменении зависимостей, если страница в фокусе
}, [navigationHistory, selectedElectionId, selectedTimeInterval, userInfo.home_ik, token]);

//Кнопка назад
const handleBackClick = () => {
  setNavigationHistory(prevHistory => {
    if (prevHistory.length > 1) {
      return prevHistory.slice(0, -1);
    }
    return prevHistory;
  });

  setIsReports(false);
  setIsBackButton(false);
};

  // const handleBackClick = () => {
  //   console.log('TEST TEST subNav', subNav)
  //   if (subNav) {
  //     if (historySub.length > 1){
  //       setHistorySub(prevHistory => {
  //         const newHistory = prevHistory.slice(0, -1);
  //         handleSquareClick(newHistory[newHistory.length - 1]);
  //         return newHistory;
  //       });
  //       setSubNav(false)
  //     }
  //     setIsReports(false);
  //     setIsBackButton(false);
  //     console.log('TEST TEST subNav YES')
  //   } else {
  //     if (history.length > 1) {
  //       setHistory(prevHistory => {
  //         const newHistory = prevHistory.slice(0, -1);
  //         handleSquareClick(newHistory[newHistory.length - 1]);
  //         return newHistory;
  //       });
  //     }
  //     setIsReports(false);
  //     setIsBackButton(false);
  //     // setIsReports(false);
  //     // setIsBackButton(false);
  //     console.log('TEST TEST subNav NO')
  //   }
  // };

   // Обработчик изменений выборов пользователя
   const handleElectionChange = (event) => {
    setNavigationHistory([{ ik_id: userInfo.home_ik, sub_id: null }]);
    setSelectedElectionId(event.target.value);
  };

  const handleIksFromAccessChange = (event) => {
    setNavigationHistory([{ ik_id: [event.target.value] , sub_id: null}]);
    setIksFromAccess(event.target.value);
  };

  const handleIksFromAccessClean = (data) => {
    //setNavigationHistory([data]);
    setNavigationHistory([{ ik_id: [data] , sub_id: null}]);
    setIksFromAccess(null);
  };


  // Обработчик изменений временного интервала
  const handleTimeIntervalChange = (event) => {
    setSelectedTimeInterval(event.target.value);
  };

  const backButton = () => (
    <Button size='medium' onClick={handleBackClick} startIcon={<ArrowBack />} sx={{color: "black" }}>
    </Button>
   );

   const pageChange = (newPage) => {
    fetchData(newPage)
  }

  const filtersButtons = () => {
    return (
      <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
            {navigationHistory.ik_id !== 15 && selectedElectionId !== null &&
            <Box sx={{ flexDirection: 'column' }}>
               <InputLabel style={{ color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10 }}>Временной интервал:</InputLabel>
               <Select value={selectedTimeInterval !== null ? selectedTimeInterval : "lastAttendanceTime"} onChange={handleTimeIntervalChange} style={{ height: 30, border: '1px solid black'}} sx={{ alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black' }}>
               {electionsTimes.find(election => election.election_id === selectedElectionId)?.pcount_deltas.map((time, index) => (
                  <MenuItem key={index} value={index}>
                     {formatDate(time.time_start)}
                  </MenuItem>
               ))}
                {selectedTimeInterval === null && (
                   <MenuItem value="lastAttendanceTime">Последнее время явки</MenuItem>
                )}
              </Select>
           </Box>
           }

            <Box sx={{flexDirection: 'column',  marginLeft: 2}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Выборы:</InputLabel>
            <Select value={selectedElectionId} style={{height: 30, border: '1px solid black'}} sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} onChange={handleElectionChange}>
               {userInfo.election_user && userInfo.election_user.map((election) => (
                  <MenuItem key={election.election_id} value={election.election_id}>
                    {election.election_name}
                  </MenuItem>
                ))}
            </Select>
            </Box>
          
          {userInfo.has_access_to_ids && userInfo.has_access_to_ids.length > 0 &&
            <Box sx={{flexDirection: 'column',  marginLeft: 2}}>
            <InputLabel style={{color: "#970D0D", fontSize: 14, fontWeight: 'bold', marginRight: 10}}>Другие избирательные комиссии:</InputLabel>
            <Select 
            value={iksFromAccess} 
            style={{height: 30, border: '1px solid black'}} 
            sx={{alignContent: 'center', justifyContent: 'center', width: '100%', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
            onChange={handleIksFromAccessChange}
            IconComponent={() => (
              <>
              {iksFromAccess && (
               <IconButton size="small" onClick={() => handleIksFromAccessClean(userInfo.home_ik)}> {/* Добавляем IconButton для очистки */}
                 <ClearSharpIcon style={{color: 'black'}}/>
               </IconButton>
               )}
               </>
           )
           }
            >
               {userInfo.access_iks_data.map((ik) => (
                  <MenuItem key={ik.ik_id} value={ik.ik_id}>
                    {ik.name}
                  </MenuItem>
                ))}
            </Select>
            </Box>
           }

            </Box>
    )
  }

  return (
    <Box m="20px" position='relative' >
      {isLoading ? (
         <Box 
         m="40px 0 0 0"
         height="75vh"
         display="flex"
         >
           <LoaderSpin width={100} height={100} textLoader="Идет загрузка"/>
        </Box>
        ):(
          <>
      <Header title={`Явка ${ikNow}`} subtitle={timeString} backButton={ isBackButton && backButton()} isVisible={!isReports} statTitle={'Явка:'} data={countIk ? `${countIk}` : 0} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        {isReports ? (
          <Box
             m="40px 0 0 0"
             height="70vh"
             display="flex"
             sx={dataGridStyles}
             width='100%'
          >
            <DataGrid 
            rows={data} 
            columns={columns} 
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSize={100}
            page={page}
            paginationMode={'server'}
            rowCount={totalPages}
            onPageChange={pageChange}
            /> 
          </Box>
        ) : (
          <>
          <Box display={'flex'} width='100%'>
          {/* <FilterComponent filtersButtons={filtersButtons()} />  */}
          <DataGridFilterCustom isSearch={false} filtersButtons={filtersButtons()} />
          </Box>
          <Box 
             display={'flex'}  
             width={'100%'} 
             height={'100%'} 
             flexWrap="wrap"
             justifyContent="flex-start"
             alignContent="flex-start" // This keeps the content aligned to the top
          >
            {data.map((squareData) => (
                <Square key={squareData.id} data={squareData} onClick={handleSquareClick} />
            ))}
            </Box>
          </>  
        )}
      </Box>
          </>
        )}
    </Box>
  );
};

export default PresenceMap;