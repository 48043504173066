import { Box, Dialog, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";

const Reader = ({ open, onClose, material }) => {
  if (!material) return null;

  const fileUrl = material.file_links[0];

  // Функция для проверки, является ли файл PDF.
  const isPdf = (url) => {
    return url?.toLowerCase().endsWith(".pdf");
  };

  // Функция для проверки, является ли ссылка видео с YouTube или RuTube.
  const isVideoLink = (url) => {
    return url?.includes("youtube.com") || url?.includes("youtu.be") || url?.includes("rutube.ru");
  };

  // Функция для генерации корректного URL для встраивания видео.
  const getEmbedUrl = (url) => {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      return `https://www.youtube.com/embed/${videoId}`;
    }
    if (url.includes("rutube.ru")) {
      const videoId = url.split('/').pop();
      return `https://rutube.ru/play/embed/${videoId}`;
    }
    return url;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
    >
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.5)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClose}
      >
        <Box
          bgcolor="white"
          width="90%"
          height="90%"
          p={2}
          position="relative"
          onClick={(e) => e.stopPropagation()}
        >
          <IconButton
            onClick={onClose}
            style={{ position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>

          {isPdf(fileUrl) ? (
            <iframe
              src={fileUrl}
              title="PDF Material"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          ) : isVideoLink(fileUrl) ? (
            <iframe
              src={getEmbedUrl(fileUrl)}
              title="Video Material"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ border: 'none' }}
            />
          ) : (
            <iframe
              src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
              title="Document Material"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

Reader.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  material: PropTypes.object
};

export default Reader;
