import {useEffect, useState} from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField, MenuItem, Select, InputLabel, Autocomplete } from '@mui/material';
import getIks from '../../../Api/HomeIk/getIks';
import getInfoIk from '../../../Api/HomeIk/getInfoIk';
import { useSelector } from 'react-redux';

const AddPfieldsForm = ({ addPfield, selectedPfield, updatePfield }) => {
    AddPfieldsForm.propTypes = {
    addPfield: PropTypes.func,
    selectedPfield: PropTypes.any,
    updatePfield: PropTypes.func,
  };

  const [pfield, setPfield] = useState({
    number: '',
    name: '',
    type_id: null,
    ik_id: null,
    election_system: null,
    ...selectedPfield
  });

  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.user);

  const electionSys = [
    { value: 1, label: "Единый округ" },
    { value: 2, label: "Мажоритарная" },
    { value: 3, label: "Смешанная" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPfield({ ...pfield, [name]: value });
  };
  

   // Обработчик выбора значения в Autocomplete
   const handleAutocompleteChange = (event, newValue) => {
    setSelectedYik(newValue);
    // Записываем значение УИКа в user.home_ik
    setPfield({ ...pfield, ik_id: newValue.ik_id });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPfield) {
      // Handle editing existing election
      // Update the existing election data
      updatePfield(pfield);
    } else {
      // Handle adding new election
      addPfield(pfield)
    }
  };

  const getDataIk = async (id) => {

   await getInfoIk({
      "ik":{
        "ik_id": id
       }
    }).then((resp)=>{
       if(resp.success){
        setSelectedYik(resp.data.ik)
       } else {
         setSelectedYik([])
         alert(resp.message)
       }
    })
  }


  useEffect(() => {
    if (selectedPfield) {
       getDataIk(pfield.ik_id)
    }

  }, [selectedPfield]);



  //Новая функция учитывающая логику поиска по типам выборов и автоочистку
const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

  setSearchLoading(true);

   //Временное решение проблемы для быстрого поиска ЦИКа
 try {
  // Запрос списка yiks с сервера для текущей страницы
  const response = await getIks({
    "user_initiator": {
      "usr_token": token
    },
    "ik": {
      "name": searchTerm,
    },
    "region":{
        "region_id": userInfo.ik_data.reg_id
    },
    //"page": currentPage
  });
  
  if(response.success){
    const yiks = response.data.iks;
    setFilteredYiks(yiks);
    setSearchLoading(false);
  }
} catch (error) {
  console.error("Ошибка при получении списка yiks:", error);
  // Обработка ошибки, например, отображение сообщения об ошибке пользователю
  setSearchLoading(false);
}
};


  return (
    <form onSubmit={handleSubmit}>

      <InputLabel id="number-pfield">Порядковый номер поля</InputLabel>
<TextField
  id="number-pfield"
  margin="none"
  name="number"
  value={pfield.number}
  onChange={handleChange}
/>
      
      <InputLabel id="name-pfield">Название поля</InputLabel>
      <TextField
            //autoFocus
            id="name-pfield"
            margin="none"
            name="name"
            // label="Название поля"
            value={pfield.name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />

           <InputLabel id="type-label">Для какого типа протокола поле?</InputLabel>
              <Select
                labelId="type-label"
                id="type-selector"
                name="type_id"
                value={pfield.type_id}
                fullWidth
                onChange={(e) =>
                  setPfield((prevData) => ({
                    ...prevData,
                    type_id: e.target.value,
                  }))
                }
              >
                  <MenuItem key={1} value={4}>
                    УИК
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    ТИК
                  </MenuItem>
                  <MenuItem key={3} value={2}>
                    РИК
                  </MenuItem>
                  <MenuItem key={4} value={1}>
                    ЦИК
                  </MenuItem>
              </Select>

            <InputLabel id="elsystem-label">Тип выборов</InputLabel>
               <Select
                   labelId="elsystem-label"
                   id="elsystem-selector"
                   name="election_system"
                   value={
                        pfield.election_system !== null
                            ? electionSys.find((type) => type.value === pfield.election_system).value
                            : ""
                   }
                  fullWidth
                  onChange={(e) =>
                    setPfield((prevData) => ({
                      ...prevData,
                      election_system: e.target.value,
                    }))
                  }
                >
                    {electionSys.map((sub) => (
                        <MenuItem key={sub.value} value={sub.value}>
                            {sub.label}
                        </MenuItem>
                    ))}
               </Select>
            
            <InputLabel id="Autocomplete-label">Привязка поля к избирательной комиссии</InputLabel>
              <FormControl fullWidth margin="none">
              <Autocomplete
                value={selectedYik}
                options={filteredYiks}
                loading={searchLoading}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} labelId="Autocomplete-label" fullWidth />}
                noOptionsText="Ничего не найдено"
                loadingText="Идет поиск. Пожалуйста, подождите!"
             />

            </FormControl>

          <Button type="submit" variant="contained" color="primary" margin="normal" sx={{marginTop: 5}}>
              {selectedPfield !== null ? "Сохранить" : "Добавить"}
          </Button>
    </form>
  );
};

export default AddPfieldsForm;