import {useEffect, useState} from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField, MenuItem, Select, InputLabel, Autocomplete } from '@mui/material';
import getParties from '../../../Api/Parties/getParties';
import getIks from '../../../Api/HomeIk/getIks';
import getInfoIk from '../../../Api/HomeIk/getInfoIk';
import { useSelector } from 'react-redux';
//import enumRegions from '../../../Api/Regions/enumRegions';
//import enumDistricts from '../../../Api/Districts/enumDistricts';

const addCandidatesForm = ({ addCandidate, selectedCandidate, updateCandidate }) => {
  addCandidatesForm.propTypes = {
    addCandidate: PropTypes.func,
    selectedCandidate: PropTypes.any,
    updateCandidate: PropTypes.func,
  };

  const [candidate, setCandidate] = useState({
    candidate_name: '',
    candidate_type: null,
    party_id: null,
    target_el: null,
    //ik_reg: null,
    //reg_id: null,
    district_id: null,
    ...selectedCandidate
  });

  const [parties, setParties] = useState([]);
  // const [regions, setRegions] = useState([]);
  // const [districts, setDistricts] = useState([]);
  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.user);

  const targetElection = [
    { value: 1, label: "Глава" },
    { value: 2, label: "Депутат" },
  ];

  const handleChange = (e) => {
    console.log(e)
    setCandidate({ ...candidate, [e.target.name]: e.target.value });
  };

   // Обработчик выбора значения в Autocomplete
   const handleAutocompleteChange = (event, newValue) => {
    setSelectedYik(newValue);
    // Записываем значение УИКа в user.home_ik
    setCandidate({ ...candidate, ik_reg: newValue.ik_id });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCandidate) {
      // Handle editing existing election
      // Update the existing election data
      updateCandidate(candidate)
    } else {
      // Handle adding new election
      addCandidate(candidate);
    }
  };

  const getDataIk = async (id) => {

   await getInfoIk({
      "ik":{
        "ik_id": id
       }
    }).then((resp)=>{
       if(resp.success){
        setSelectedYik(resp.data.ik)
       } else {
         setSelectedYik([])
         alert(resp.message)
       }
    })
  }

  const getDataParties = async () => {
    await getParties({}).then((resp)=>{
      if (resp.success) setParties(resp.data.parties)
      else {
        setParties([])
        alert(resp.message)
      }
    })
  }

  // const getDataRegions = async () => {
  //   await enumRegions({}).then((resp)=>{
  //     if (resp.success) setRegions(resp.data.regions)
  //     else {
  //       setRegions([])
  //       alert(resp.message)
  //     }
  //   })
  // }

  // const getDataDistricts = async (id) => {
  //   let payload = {
  //     "region":{
  //       "region_id":id
  //     }
  //   }
  //   await enumDistricts(payload).then((resp)=>{
  //     if (resp.success) setDistricts(resp.data.districts)
  //     else {
  //       setDistricts([])
  //       alert(resp.message)
  //     }
  //   })
  // }

  console.log(userInfo)


  useEffect(()=> {
    getDataParties();
  },[])

  // useEffect(() => {
  //   if (candidate.reg_id) {
  //      getDataDistricts(candidate.ik_reg)
  //   }

  // }, [candidate.reg_id]);

  useEffect(() => {
    if (selectedCandidate) {
       getDataIk(candidate.ik_reg)
    }

  }, [selectedCandidate]);



  //Новая функция учитывающая логику поиска по типам выборов и автоочистку
const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

  setSearchLoading(true);

   //Временное решение проблемы для быстрого поиска ЦИКа
 try {
  // Запрос списка yiks с сервера для текущей страницы
  const response = await getIks({
    "user_initiator": {
      "usr_token": token
    },
    "ik": {
      "name": searchTerm,
    },
    "region":{
        "region_id": userInfo.ik_data.reg_id
    },
    //"page": currentPage
  });
  
  if(response.success){
    const yiks = response.data.iks;
    setFilteredYiks(yiks);
    setSearchLoading(false);
  }
} catch (error) {
  console.error("Ошибка при получении списка yiks:", error);
  // Обработка ошибки, например, отображение сообщения об ошибке пользователю
  setSearchLoading(false);
}
};


  return (
    <form onSubmit={handleSubmit}>
      <TextField
            //autoFocus
            margin="dense"
            name="candidate_name"
            label="ФИО кандидата"
            value={candidate.candidate_name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />

          {/* <FormControl fullWidth margin="normal">
              <InputLabel>Регион кандидата *(поле может быть пустым)</InputLabel>
              <Select
                name="reg_id"
                value={candidate.reg_id}
                onChange={(e) =>
                  setCandidate((prevData) => ({
                    ...prevData,
                    reg_id: e.target.value,
                  }))
                }
              >
                  {regions.map((region) => (
                  <MenuItem key={region.region_id} value={region.region_id}>
                    {region.name}
                  </MenuItem>
                    ))}
              </Select>
            </FormControl>

            { (candidate.reg_id && districts) && 

            <FormControl fullWidth margin="normal">
              <InputLabel>Район кандидата *(поле может быть пустым)</InputLabel>
              <Select
                name="district_id"
                value={candidate.district_id}
                onChange={(e) =>
                  setCandidate((prevData) => ({
                    ...prevData,
                    district_id: e.target.value,
                  }))
                }
              >
                  {districts.map((region) => (
                  <MenuItem key={region.district_id} value={region.district_id}>
                    {region.district_name}
                  </MenuItem>
                    ))}
              </Select>
            </FormControl>
           } */}

           <FormControl fullWidth margin="normal">
              <InputLabel>Выберите партию</InputLabel>
              <Select
                name="party_id"
                value={candidate.party_id}
                onChange={(e) =>
                  setCandidate((prevData) => ({
                    ...prevData,
                    party_id: e.target.value,
                  }))
                }
              >
                {parties.map((partie) => (
                  <MenuItem key={partie.id} value={partie.id}>
                    {partie.short_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

        <FormControl fullWidth margin="normal">
              <InputLabel id="targetEl-label">Должность</InputLabel>
              <Select
                labelId="targetEl-label"
                id="targetEl-selector"
                name="target_el"
                value={
                  candidate.target_el !== null
                  ? targetElection.find((type) => type.value === candidate.target_el).value
                 : ""
              }
              fullWidth
              onChange={handleChange}
              >
                {targetElection.map((target) => (
                  <MenuItem key={target.value} value={target.value}>
                    {target.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <InputLabel id="Autocomplete-label">Регистрация кандидата на ИК</InputLabel>
              <FormControl fullWidth margin="none">
              <Autocomplete
                value={selectedYik}
                options={filteredYiks}
                loading={searchLoading}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} labelId="Autocomplete-label" fullWidth />}
                noOptionsText="Ничего не найдено"
                loadingText="Идет поиск. Пожалуйста, подождите!"
             />

            </FormControl>

          <Button type="submit" variant="contained" color="primary" margin="normal" sx={{marginTop: 5}}>
              {selectedCandidate !== null ? "Сохранить" : "Добавить"}
          </Button>
    </form>
  );
};

export default addCandidatesForm;