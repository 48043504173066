

export const IconFire = (
    `<svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.83 0C14.745 9.83 0 14.745 0 24.575C0 34.405 9.83 39.32 9.83 39.32C5.0133 29.5883 19.66 24.575 19.66 14.745C19.66 4.915 9.83 0 9.83 0ZM24.575 14.745C29.49 24.575 14.745 29.49 14.745 39.32H29.49C31.456 39.32 34.405 36.8625 34.405 29.49C34.405 19.66 24.575 14.745 24.575 14.745Z" fill="#C40B0B"/>
    </svg>`
  );

export const FilesIcon = (
    `<svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3845 0C15.6087 0 13.8328 0.731248 12.4747 2.05446L2.79441 11.5259C-0.931471 15.2517 -0.931471 21.2758 2.79441 25.0365C6.52029 28.7624 12.5444 28.7624 16.3051 25.0365L20.6578 20.6839L18.2551 18.2812L14.2158 22.216L13.9024 22.6687C11.4997 25.0714 7.59975 25.0714 5.19708 22.6687C2.82923 20.3008 2.89888 16.4705 5.19708 14.0678L14.8774 4.49195C16.2354 3.13392 18.4988 3.13392 19.8917 4.49195C21.2497 5.84998 21.1801 8.00891 19.8917 9.40176L11.1864 18.0026C10.8381 18.3508 10.2462 18.3508 9.86314 18.0026C9.51493 17.6544 9.51493 17.0625 9.86314 16.6794L10.0721 16.575L13.2408 13.3017L10.8381 10.8991L7.46047 14.2767C5.78905 15.9482 5.78905 18.6991 7.46047 20.3705C9.13189 22.0419 11.8828 22.0767 13.5542 20.3705L22.2595 11.8741C24.9756 9.15801 24.9756 4.77052 22.2595 2.08928C20.9015 0.731248 19.1604 0.0348213 17.3497 0.0348213L17.3845 0Z" fill="#C40B0B"/>
    </svg>
    `
);

export const EyeIcon = (`
<svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5819 0C5.4275 0 0 10.855 0 10.855C0 10.855 5.4275 21.71 14.5819 21.71C23.5192 21.71 28.9467 10.855 28.9467 10.855C28.9467 10.855 23.5192 0 14.5819 0ZM14.4733 3.61833C18.4897 3.61833 21.71 6.87483 21.71 10.855C21.71 14.8713 18.4897 18.0917 14.4733 18.0917C10.4932 18.0917 7.23667 14.8713 7.23667 10.855C7.23667 6.87483 10.4932 3.61833 14.4733 3.61833ZM14.4733 7.23667C12.4832 7.23667 10.855 8.86492 10.855 10.855C10.855 12.8451 12.4832 14.4733 14.4733 14.4733C16.4634 14.4733 18.0917 12.8451 18.0917 10.855C18.0917 10.4932 17.9469 10.1675 17.8746 9.84187C17.5851 10.4208 17.0062 10.855 16.2825 10.855C15.2694 10.855 14.4733 10.059 14.4733 9.04583C14.4733 8.32217 14.9075 7.74323 15.4865 7.45377C15.1608 7.34522 14.8352 7.23667 14.4733 7.23667Z" fill="black"/>
</svg>
`);