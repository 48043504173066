import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle,useTheme } from '@mui/material';
//import axios from 'axios';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddProtocolForm from "./addProtocolForm"
import EditProtocolForm from './editProtocolForm';
//import getElections from '../../Api/Elections/getElections';
//import updateElections from '../../Api/Elections/updateElections';
import { dataGridStyles } from '../../styleGrid';
import { tokens } from '../../theme';
//import getInfoElections from '../../Api/Elections/getInfoElections';
import getProtocolsSchemas from '../../Api/Protocols/getProtocolsSchemas';
import createProtocolsSchemas from '../../Api/Protocols/createProtocolsSchemas';
import updateProtocolsSchemas from '../../Api/Protocols/updateProtocolsSchemas';
import getInfoProtocolsSchemas from '../../Api/Protocols/getInfoProtocolsSchemas';
import deleteProtocolsSchemas from '../../Api/Protocols/deleteProtocolsSchemas';
import { useSelector } from 'react-redux';

const protocols = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [protocols, setProtocols] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [force, setForce] = useState(true);
  const [selectedProtocol, setSelectedProtocol] = useState(false);
  const token = useSelector((state) => state.user.token);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "protocol_name",
      headerName: "Протокол",
      flex:1,
      cellClassName: "name-column--cell",
    },
    {
      field: "protocol_type",
      headerName: "Тип протокола",
      width: 200,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <>{typeProtocol(params.row.protocol_type)}</>
      ),
    },
    {
      field: 'control',
      flex: 1,
      headerName: 'Управление',
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleEditClick(params.row)}
        >
          Редактировать
        </Button>

        <Button
          variant="outlined"
          style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleDeleteProtocol(params.row.id)}
        >
          Удалить
        </Button>
        </>
      ),
    },
  ];

  const typeProtocol = (type) => {
      if (type === 1){
        return "Протокол ЦИК"
      } else if(type === 2){
        return "Протокол РИК"
      } else if (type === 3){
        return "Протокол ТИК"
      } else {
        return "Протокол УИК"
      }
  }

  const handleGetProtocols = async () => {
    
    let payload = {
      "user_initiator":{
          "usr_token": token
       }
    }

    await getProtocolsSchemas(payload).then(response => {

      if(response.success){
        //const protocolArrays = processProtocolSchemas(response.data.elections);
          const rows = response.data.el_protocols.map(protocol => {
              return {
                id: protocol.protocol_id,
                ...protocol
              };
          });

          setProtocols(rows);
          setForce(false);
      } else {
          setProtocols([]);
          setForce(false);
          alert(response.message)
       }
    })
  }
  
  useEffect(() => {
    if (force){
      handleGetProtocols()
    }
  }, [force]);


  const handleEditClick = async (protocol) => {
    //const result = [];

     let payload = {
     "user_initiator":{
         "usr_token":token,
     },
     "el_protocol": {
      "protocol_id": protocol.id
      }
   }

   await getInfoProtocolsSchemas(payload).then((resp) => {

     if (resp.success) {

         // Парсим JSON-строку в объект
         const protocolObject = JSON.parse(resp.data.el_protocol.data);
     
         // Добавляем в результат
         let obj = {
           ...protocolObject,
           ...protocol
         };

         setSelectedProtocol(obj);
         setEdit(true);
     } else {
       alert(resp.message)
     }
 })
};

  // const handleEditClick = (protocol) => {
  //   setSelectedProtocol(protocol);
  //   setEdit(true);
  // };

  const CloseEdit = () => {
    setEdit(false);
  };

  const createProtocol =  async (formData) => {

    // Преобразование в строку протокола
    const protocolDataString = JSON.stringify(formData);

    let payload = {
        "user_initiator":{
            "usr_token": token
        },
         "el_protocol": {
          "protocol_name": formData.protocol_name,
          "protocol_type": formData.protocol_type,
          "elections_type": formData.elections_type,
          "election_id": formData.election_id,
          "description": formData.description,
          "sub_id": formData.sub_id,
          "data": protocolDataString
  }
    }

    createProtocolsSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setForce(true);
        setOpen(false);
      } else{
        alert(resp.message)
      }
    })
  }

  const updateProtocol =  async (formData) => {

    // Преобразование в строку протокола
    const protocolDataString = JSON.stringify(formData);

    let payload = {
        "user_initiator":{
            "usr_token": token
        },
         "el_protocol": {
          "protocol_id": formData.id,
          "protocol_name": formData.protocol_name,
          "protocol_type": formData.protocol_type,
          "elections_type": formData.elections_type,
          "election_id": formData.election_id,
          "description": formData.description,
          "data": protocolDataString
       }
    }

    updateProtocolsSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setForce(true);
        setEdit(false);
      } else{
        alert(resp.message)
      }
    })
  }

  const handleDeleteProtocol = async (roadMapId) => {

    let payload = {
      "user_initiator":{
          "usr_token": token
      },
      "el_protocol": {
       "protocol_id": roadMapId
       }
      }

    await deleteProtocolsSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setForce(true);
      } else {
        alert(resp.message)
      }
    })
  };

  // const handleAddProtocol = async (formData) => {
  //   console.log("TEST PROTOCOLS formData", formData)
  //    try {
  //     // Стучимся в backend для получения выборов
  //     const response = await getInfoElections({
  //       "election": {
  //         "election_id": formData.elections_id
  //       }
  //     });

  //     console.log("TEST PROTOCOLS getInfoElections", response)

  //     const old_protocol_schema = 
  //         response.data.election.protocol_schema ? 
  //           JSON.parse(response.data.election.protocol_schema) 
  //         : null;

  //     let newProtocolSchema = {
  //       ...old_protocol_schema,
  //       [formData.protocolType]: formData
  //     }

  //     //console.log("TEST PROTOCOLS newProtocolSchema", newProtocolSchema)
  
  //     // Преобразование в строку протокола
  //     const protocolDataString = JSON.stringify(newProtocolSchema);

  //     // Присоединяем протокол
  //     const updatedElections =  {
  //           ...response.data.election,
  //           protocol_schema: protocolDataString
  //       }

  //       //console.log("TEST PROTOCOLS FINAL", updatedElections)
  
  //     // Обновляем выборы и присоединяем к ним созданный протокол
  //     const dataToSend = {
  //       "user_initiator": {
  //         "usr_token": token
  //       },
  //       "election": {
  //         ...updatedElections // Directly use the first updated election
  //       }
  //     };
  
  //     // Вызываем backend для обновления выборов
  //     const updateResponse = await updateElections(dataToSend);
  
  //     if (updateResponse.success) {
  //       setForce(true);
  //       setOpen(false);
  //       if (edit){
  //         setEdit(false);
  //       }
  //     }
  //   } catch (error) {
  //     // Обработка ошибок
  //     console.error("TEST PROTOCOLS Error updating election:", error);
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAddDialog = () => {
    return (
      <div>
        <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}}  onClick={handleClickOpen}>
          Добавить протокол
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Создайте протокол"}
          </DialogTitle>
          <DialogContent>
            <AddProtocolForm onSubmit={createProtocol} />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const handleOpenEditDialog = () => {
    return (
      <div>
       <Dialog
        open={edit}
        onClose={CloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Редактировать протокол</DialogTitle>
        <DialogContent>
          <EditProtocolForm protocolData={selectedProtocol} onSave={updateProtocol} />
        </DialogContent>
      </Dialog>
      </div>
    );
  };

  return (
    <Box m="20px">
    <Header title="Протоколы системы" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
        {handleOpenEditDialog()}
       <DataGrid checkboxSelection rows={protocols} columns={columns} components={{ Toolbar: handleOpenAddDialog  }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />
    </Box>
    </Box>
  );
};

export default protocols;