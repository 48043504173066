import { Box, Button, Grid, Typography, TextField, IconButton } from "@mui/material";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import updateSelf from "../../Api/Users/updateSelf";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNav = location.state.nav;
  const isUserCurrent = isNav === "me";
  const userInfo = useSelector((state) => state.user.user);
  const [userData, setUserData] = useState(isUserCurrent ? userInfo : location.state.userData);
  const token = useSelector((state) => state.user.token);

  //TODO: Пофиксить баг передачи данных после обновления страницы!!!!

  const handleBackClick = () => {
    navigate(-1);
  };

  const backButton = () => (
    <Button size='medium' onClick={handleBackClick} startIcon={<ArrowBack />} sx={{color: "black" }}>
    </Button>
   );

   const [editMode, setEditMode] = useState({ email: false, phone: false });

  const toggleEdit = (field) => {
    setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleDataChange = (event, field) => {
    setUserData({ ...userData, [field]: event.target.value });
    console.log(event, field)
  };

  const handleCancelEdit = (field) => {
    setUserData({ ...userInfo, [field]: userInfo[field] });
    toggleEdit(field);  // This reuses the existing toggleEdit to close the edit mode
  };

  const handleSave = (field) => {
    toggleEdit(field);
    // Call a function to handle the save operation
    updateInformation();
  };

  const updateInformation = async () => {
     
    let payload = {
      "user_initiator":{
        "usr_token": token,
        "usr_name":  userData.usr_name,
        "usr_phone": userData.usr_phone,
        "usr_email": userData.usr_email,
        "home_ik": userData.home_ik
      }
    }

    await updateSelf(payload).then((res) => {
        if (res.success){
          alert('Успех');
        }else {
          alert(res.message);
        }
    })

  }

  const textFieldStyle = {
    input: {
      fontSize: '24px', // matching Typography font size
      color: 'black', // matching Typography color
      lineHeight: '29.05px', // matching Typography line height
    },
    notchedOutline: {
      borderWidth: '0px' // removing border
    }
  };

   return (
    <Box m="20px">
      {/* HEADER */}
      <Header title="Профиль" backButton={!isUserCurrent && backButton()}/>
      
      <Grid container spacing={3}> {/* Создание контейнера Grid */}
        {/* Grid для данных о пользователе */}
        <Grid item xs={6}> {/* Использование половины ширины */}
          <Box style={{marginTop: 37}}> 
            {/* ФИО */}
            <Typography
                component="div"
                fontWeight={400}
                fontSize="24px"
                lineHeight="29.05px"
                marginBottom={1}
                marginTop={2}
              > ФИО:</Typography>
            <Box
              width="550px"
              height="38px"
              borderRadius="11px"
              bgcolor="rgb(217, 217, 217,50%)"
              alignContent={'center'}
              display={'flex'}
              justifyContent={'flex-start'}
            >
              <Typography
                component="div"
                fontWeight={400}
                fontSize="24px"
                marginLeft={2}
              > {userData.usr_name} </Typography>
            </Box>

            {/* Почта */}
            <Typography component="div" fontWeight={400} fontSize="24px" lineHeight="29.05px" marginBottom={1} marginTop={2}>Почта:</Typography>
            {editMode.email ? (
              <Box 
              width="550px"
              height="38px"
              borderRadius="11px"
              bgcolor="rgb(217, 217, 217,50%)"
              alignItems={'center'}
              display={'flex'}
              justifyContent={'flex-start'}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  value={userData.usr_email}
                  marginLeft={2}
                  onChange={(e) => handleDataChange(e, 'usr_email')}
                  InputProps={{
                    style: textFieldStyle.input,
                    disableUnderline: true, // removes the underline in text field
                  }}
                  sx={{
                    fieldset: { border: 'none' }, // removes border for outlined variant
                  }}
                />
                <IconButton onClick={() => handleSave('email')}>
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={() => handleCancelEdit('email')}>
                  <CloseIcon />
               </IconButton>
              </Box>
            ) : (
              <Box 
                 width="550px"
                 height="38px"
                 borderRadius="11px"
                 bgcolor="rgb(217, 217, 217,50%)"
                 alignContent={'center'}
                 display={'flex'}
                 justifyContent={'flex-start'}
              >
                <Typography component="div" fontWeight={400} fontSize="24px" marginLeft={2}>{userData.usr_email}</Typography>
                {isUserCurrent &&
                <IconButton onClick={() => toggleEdit('email')}>
                  <EditIcon />
                </IconButton>
                 }
              </Box>
            )}

            {/* Телефон */}
            <Typography component="div" fontWeight={400} fontSize="24px" lineHeight="29.05px" marginBottom={1} marginTop={2}>Телефон:</Typography>
            {editMode.phone ? (
              <Box  
                 width="550px"
                 height="38px"
                 borderRadius="11px"
                 bgcolor="rgb(217, 217, 217,50%)"
                 alignItems={'center'}
                 display={'flex'}
                 justifyContent={'flex-start'}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  value={userData.usr_phone}
                  onChange={(e) => handleDataChange(e, 'usr_phone')}
                  InputProps={{
                    style: textFieldStyle.input,
                    disableUnderline: true, // removes the underline in text field
                  }}
                  sx={{
                    fieldset: { border: 'none' }, // removes border for outlined variant
                  }}
                />
                <IconButton onClick={() => handleSave('phone')}>
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={() => handleCancelEdit('phone')}>
                  <CloseIcon />
               </IconButton>
              </Box>
            ) : (
              <Box 
              width="550px"
              height="38px"
              borderRadius="11px"
              bgcolor="rgb(217, 217, 217,50%)"
              alignContent={'center'}
              display={'flex'}
              justifyContent={'flex-start'}
              >
                <Typography component="div" fontWeight={400} fontSize="24px" marginLeft={2}>{userData.usr_phone}</Typography>
                {isUserCurrent &&
                <IconButton onClick={() => toggleEdit('phone')}>
                  <EditIcon />
                </IconButton>
                }
              </Box>
            )}

            {/* Статус */}
            <Typography
                component="div"
                fontWeight={400}
                fontSize="24px"
                lineHeight="29.05px"
                marginBottom={1}
                marginTop={2}
              > Статус: </Typography>
            <Box
             width="550px"
              height="38px"
              borderRadius="11px"
              bgcolor="rgb(217, 217, 217,50%)"
              alignContent={'center'}
              display={'flex'}
              justifyContent={'flex-start'}
            >
              <Typography
                component="div"
                fontWeight={400}
                fontSize="24px"
                marginLeft={2}
              > {userData.status_data?.name || "Не установлен"} </Typography>
            </Box>
            {/* ИК */}
        <Typography
            component="div"
            fontWeight={400}
            fontSize="24px"
            lineHeight="29.05px"
            marginBottom={1}
            marginTop={2}
          > Избирательная комиссия: </Typography>
        <Box
          width="550px"
          height="38px"
          borderRadius="11px"
          bgcolor="rgb(217, 217, 217,50%)"
          alignContent={'center'}
          display={'flex'}
          justifyContent={'flex-start'}
        >
             <Typography
            component="div"
            fontWeight={400}
            fontSize="24px"
            marginLeft={2}
          > {userData.ik_data?.name || "Не установлен"} </Typography>
        </Box>

        {/* Роль в системе */}
        <Typography
            component="div"
            fontWeight={400}
            fontSize="24px"
            lineHeight="29.05px"
            marginBottom={1}
            marginTop={2}
          > Роль в системе: </Typography>
        <Box
          width="550px"
          height="40px"
          borderRadius="11px"
          bgcolor="rgb(217, 217, 217,50%)"
          alignContent={'center'}
          display={'flex'}
          justifyContent={'flex-start'}
        >
            <Typography
            component="div"
            fontWeight={400}
            fontSize="24px"
            marginLeft={2}
          > {userData.access_data?.access_display_name || "Не установлена"} </Typography>
        </Box>
          </Box>
        </Grid>

        {/* Grid для данных о доступе к избирательным комиссиям */}
        <Grid item xs={6}> {/* Использование половины ширины */}
        {userData.access_iks_data && userData.access_iks_data.length > 0 &&
          <Box style={{marginTop: 37}}>
            {/* Избирательные комиссии */}
            <Typography
                component="div"
                fontWeight={400}
                fontSize="24px"
                lineHeight="29.05px"
                marginBottom={1}
                marginTop={2}
              > Доступ к избирательным комиссиям: </Typography>
            {userData.access_iks_data.map((item, index) => (
              <Box
                key={index}
                width="550px"
                height="38px"
                borderRadius="11px"
                bgcolor="rgb(217, 217, 217,50%)"
                alignContent={'center'}
                display={'flex'}
                justifyContent={'flex-start'}
                marginBottom={1}
                marginTop={2}
              >
                <Typography
                  component="div"
                  fontWeight={400}
                  fontSize="24px"
                  marginLeft={2}
                > {item.name} </Typography>
              </Box>
            ))}
          </Box>
        }
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;