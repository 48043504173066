import { useEffect, useState } from 'react';
import { 
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  Divider,
} from '@mui/material';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import IncendentDetail from '../mapViolations/incendentDetail';
import getListIncidents from '../../Api/incidents/getListIncidents';
import getIncidentInfo from '../../Api/incidents/getIncidentInfo';
import { dataGridStyles } from '../../styleGrid';
import { tokens } from '../../theme';
import { formatDate } from '../../data/Utils/timeZones';
import { useNavigate } from 'react-router-dom';
import getInfoUser from '../../Api/Users/getInfoUser';
import setPublishIncidents from '../../Api/incidents/setPublishIncidents';
import { useSelector } from 'react-redux';
//import DataGridFilterCustom from '../../components/DataGridFilterCustom';

const PublishMapOfViolations = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [incendent, setSelectedIncendent] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);
  const [page, setPage] = useState(0);
  //const [filteredRows, setFilteredRows] = useState([]);
  let totalPages = 100000;

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "user",
      headerName: "Кто отправил?",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.user_id)}>
          {params.row.user}
        </div>
      )
    },
    {
      field: "date",
      headerName: "Дата",
      width: 150,
      renderCell: (params) => (
        formatDate(params.row.date)
      ),
    },
    {
      field: "ElectionName",
      headerName: "Выборы",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "incendentIs",
      headerName: "ИК",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "message",
      headerName: "Краткое описание",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "view",
      headerName: "Действия",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleViewIncendent(params.row)}
          >
            Посмотреть
          </Button>
        </>
      ),
    },
  ];

  const fetchData = async (newPage=page) => {
    const response = await getListIncidents({
      "user_initiator": {
          "usr_token": token
      },
      "incident": {
        "is_public":true
      },
      "page": newPage
    });
    if (response.success) {
      setData(response.data.incidents);
    } else {
      setData([]);
      alert(response.message);
    }
    setForce(false);
    setPage(newPage)
  };

  useEffect(() => {

    fetchData();

    const interval = setInterval(fetchData, 60 * 1000); 
    return () => clearInterval(interval);
  }, [force, token]); 


  const handleViewIncendent = async (incendent) => {
    await getIncidentInfo({
      "user_initiator":{
        "usr_token":token
      },
      "incident":{
        "incident_id":incendent.id
       }
    }).then((response)=>{
      if (response.success){
        const meta = JSON.parse(response.data.incident.meta);
        const newData = {
          "TimeReport" : meta.TimeReport,
          "AcceptMedia": meta.AcceptMedia,
          "AcceptElectoral": meta.AcceptElectoral,
          ...response.data.incident
        }
        setSelectedIncendent(newData);
        setDialogOpen(true);
      } else {
        //alert('Проблема с сервером!')
        alert(response.message)
      }
    })
  };

   const handlePublish = (id) => {

    setPublishIncidents({
      "user_initiator":{
        "usr_token":token
      },
      "incident":{
       "incident_id": id,
       "is_public":false
      }
    }).then((response) => {
      if(response.success){
         setDialogOpen(false);
         setForce(true)
      } else {
        alert(response.message)
      }
    }).catch((error) => {
          // Обработка ошибки при удалении протокола
           console.error("Ошибка при назначении протокола:", error);
           alert(error.message)
    });

 }
 


  // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};

  const dialogIncendent = () => {
    return (
      <>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" width={"90%"}>
             <DialogTitle style={{fontWeight:'700', fontSize: 20}}>Детали нарушения</DialogTitle>

             <Button
                variant="outlined"
                style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
                size="small"
                onClick={() => handlePublish(incendent.incident_id)}
             >
                Снять с публикации
            </Button>

           </Box>
          {/* <DialogTitle style={{fontWeight:'700', fontSize: 20}}>Детали нарушения</DialogTitle> */}
          <Divider />
          <DialogContent>
            {incendent && (
              <IncendentDetail incendent={incendent} />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const rows = data.map(incident => {
    //console.log('TEST REPLANZ', incident)
    //const meta = incident.meta ? JSON.parse(incident.meta) : incident.created_at; //TODO: напомнить отдать мету в getList
    return {
      id: incident.incident_id,
      ElectionName: incident.election_data.election_name,
      typeElection: incident.election_data.election_type,
      date: incident.created_at,
      user: incident.user_data.usr_name,
      //protocolType: incident.is_dupe ? "Повторный" : "Первичный",
      incendentIs: incident.ik_data.name,
      message: incident.incident_title ? incident.incident_title : "",
      ...incident
    };
  });

  const pageChange = (newPage) => {
    fetchData(newPage)
  }

  // const filteredColumns = (columnsToFilter) => {
  //   // Исключаем колонки access_id и control
  //   return columnsToFilter.filter(column => column.field !== "access_id" && column.field !== "view");
  // };


  return (
    <Box m="20px">
    <Header title="Опубликованные нарушения" />
    <Box sx={{mt: 2}}>

       {/* <DataGridFilterCustom columns={filteredColumns(columns)} rows={rows} setFilteredRows={setFilteredRows} /> */}

    </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
        {dialogIncendent()}
       <DataGrid 
       checkboxSelection 
       rows={rows}
       columns={columns} 
       localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} 
       pageSize={100}
       paginationMode={'server'}
       rowCount={totalPages}
       onPageChange={pageChange}
       />
    </Box>
    </Box>
  );
};

export default PublishMapOfViolations;