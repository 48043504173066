import { Box, IconButton, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import { useContext, useState} from "react";
import { ColorModeContext, tokens } from "../../theme";
//import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
//import SearchIcon from "@mui/icons-material/Search";
import PropTypes from 'prop-types';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsMenu from "./Notifications";
import { useNavigate } from "react-router-dom";
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import ChangePasswordModal from "../../modals/ModalPassword/ModalPassword";
import KeySharpIcon from '@mui/icons-material/KeySharp';
import { useSelector } from "react-redux";

const Topbar = ({ handleLogout }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const userInfo = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  
    Topbar.propTypes = {
      handleLogout: PropTypes.any.isRequired,
    };
  
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [profileMenu, setProfileMenu] = useState(false);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  
    const handleProfileMenuClick = (event) => {
      console.log("Profile menu clicked");
      setProfileAnchorEl(event.currentTarget);
      setProfileMenu(true);
    };

    const handleNotificationsMenuClick = (event) => {
      setNotificationsAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setProfileMenu(false);
      setProfileAnchorEl(null);
      setNotificationsAnchorEl(null);
    };
  
    const handleProfileClick = () => {
      navigate('/me', { state: { userData: userInfo, nav: 'me'} });
    };

    const handleOpenChangePassword = () => {
      setChangePasswordModalOpen(true);
      handleMenuClose(); // Close the profile menu
    };
  
    const handleLogin = () => {
      setProfileMenu(false);
      navigate('/login');
    };

    const handleDeauth = () => {
      setProfileMenu(false);
      setProfileAnchorEl(null);
      setNotificationsAnchorEl(null);
      handleLogout()
    };
  
     // Замените notifications на реальные данные об уведомлениях
   const notifications = [
    // { title: 'Уведомление 1', time: '12:00' },
    // { title: 'Уведомление 2', time: '12:30' },
    // { title: 'Уведомление 3', time: '13:00' },
    // { title: 'Уведомление 3', time: '13:00' },
    // { title: 'Уведомление 3', time: '13:00' },
  ];
  
    return (
      <Box display="flex" p={2} backgroundColor={colors.redAccent[1000]}  width='100%'>
  
        <Box display="flex" width='100%' flexDirection={'row'}>
          {token && isLoggedIn ? (
            <Box display="flex" width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
              <IconButton
                aria-controls="notifications-menu"
                aria-haspopup="true"
                onClick={handleNotificationsMenuClick}
              >
                <NotificationsOutlinedIcon />
              </IconButton>
              <NotificationsMenu
                notifications={notifications}
                anchorEl={notificationsAnchorEl}
                onClose={handleMenuClose}
              />
              <IconButton 
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={(event) => handleProfileMenuClick(event)}
              >
                <PersonOutlinedIcon />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={profileAnchorEl}
                keepMounted
                open={profileMenu}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    borderRadius: '10px',
                    width: '159px',
                    maxHeight: '227px',
                    overflow: 'auto',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                }}
              >
                <MenuItem onClick={handleProfileClick} style={{color: colors.primary[1000]}}>
                  <PersonOutlinedIcon style={{color: colors.redAccent[1000] }}/>
                  <ListItemText style={{marginLeft: 10}} primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '14px' } }}>Профиль</ListItemText> 
                </MenuItem>
                <MenuItem onClick={()=> 0} style={{color: colors.primary[1000]}}>
                  <SettingsOutlinedIcon style={{color: colors.redAccent[1000] }}/>
                  <ListItemText style={{marginLeft: 10}} primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '14px' } }}>Настройки</ListItemText> 
                </MenuItem>
                <MenuItem onClick={handleOpenChangePassword} style={{color: colors.primary[1000]}}>
                  <KeySharpIcon style={{color: colors.redAccent[1000] }}/>
                  <ListItemText style={{marginLeft: 10}} primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '14px' } }}>Смена пароля</ListItemText> 
                </MenuItem>
                <MenuItem onClick={handleDeauth} sx={{ mt: '77px', color: colors.primary[1000] }}>
                  <LogoutIcon style={{color: colors.redAccent[1000] }} />
                  <ListItemText style={{marginLeft: 10}} primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '14px' } }}>Выход</ListItemText>
                </MenuItem>
              </Menu>

              <ChangePasswordModal
                  open={changePasswordModalOpen}
                  onClose={() => setChangePasswordModalOpen(false)}
                />
            </Box>
          ) : (
            <Box display="flex" width='100%' justifyContent={'space-between'}>

                  <Box display="flex" >
                      <img src={require("../../Icons/LogoNew2024.png")} style={{width: 250, height: 70, marginRight: 'auto'}}/>
                  </Box>

                  <Box display="flex" >
                      <IconButton
                        aria-controls="login-to-panel"
                        onClick={handleLogin}
                       >
                        Войти
                       <LoginSharpIcon/>
                     </IconButton>
                  </Box>

            </Box>
          )}
        </Box>
      </Box>
    );
  };
  
  export default Topbar;

// ///  {/* SEARCH BAR */}
//       {/* <Box
//         display="flex"
//         backgroundColor={colors.primary[1400]}
//         borderRadius="3px"
//       >
//         <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Поиск" style={{color: colors.primary[1000]}} />
//         <IconButton type="button" sx={{ p: 1 }}>
//           <SearchIcon />
//         </IconButton>
//       </Box> */}