import { tryParseJSON } from '../../data/Utils/converters';
import { SET_TOKEN, SET_USER, SET_IS_LOGGED_IN } from '../Actions/userActions';
import Cookies from 'js-cookie';

// Initial State
const initialState = {
  token: Cookies.get('token') || null,
  user: {
    home_ik: null,
    election_user: [],
    access_id: null,
    access_data: {
      access_id: null
    },
    ...tryParseJSON(Cookies.get('user')) || null
  },
  isLoggedIn: tryParseJSON(Cookies.get('isLoggedIn')) || false,
};

// Reducer Function
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};
