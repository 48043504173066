import { useEffect, useState } from 'react';
import { Box, Button, Dialog ,DialogContent ,DialogTitle, useTheme  } from '@mui/material';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import AddVideoLectionsForm from "./addVideoLectionsForm"
import getResources from '../../../Api/Resources/getResources';
import createResources from '../../../Api/Resources/createResources';
import { dataGridStyles } from '../../../styleGrid';
import getInfoFromMarterial from '../../../Api/Resources/getInfoFromMarterial';
import updateResources from '../../../Api/Resources/updateResources';
import { tokens } from '../../../theme';
import deleteResources from '../../../Api/Resources/deleteResources';
import Reader from '../../../shared/Reader';
import { useSelector } from 'react-redux';

const videoLections = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [handBooks, setHandBooks] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [isReaderOpen, setIsReaderOpen] = useState(false);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "VideoTitle",
      headerName: "Название",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleOpenReader(params.row)}>
          {params.value}
          </div>
      ),
    },
    {
      field: "VideoSubtitle",
      headerName: "Описание",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: 'control',
      flex: 1,
      headerName: 'Управление',
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleEditClick(params.row.resource_id)}
        >
          Редактировать
        </Button>

        <Button
          variant="outlined"
          style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => deleteResourcesFromId(params.row.resource_id)}
        >
          Удалить
        </Button>
        </>
      ),
    },
  ];

  const handleOpenReader = (material) => {
    setSelectedMaterial(material);
    setIsReaderOpen(true);
  };

  const handleCloseReader = () => {
    setIsReaderOpen(false);
    setSelectedMaterial(null);
  };

  useEffect(() => {
    let payload = {
      "resource":{
        "type_id":4
       },
    }
    getResources(payload).then((response) => {
      if(response.success){
        setHandBooks(response.data.resources);
      } else {
        setHandBooks([])
        alert(response.message)
      }
      setForce(false);
    });
  }, [force]);

  const addMaterial = async (material) => {
    let payload = {
        "user_initiator": {
          "usr_token": token
      },
      "resource": {
        ...material
       }
    }

    await createResources(payload).then((resp) => {
        if (resp.success){
          setOpen(false);
          setForce(true)
        } else {
          alert(resp.message)
       }
      })
    }

    const deleteResourcesFromId = async (id) => {
      let payload = {
        "user_initiator": {
          "usr_token": token
      },
      "resource": {
          "resource_id": id
      }
      }
      await deleteResources(payload).then((resp) => {
        if(resp.success){
          setForce(true)
        } else {
          alert(resp.message)
        }
      })
    }

    const updateMaterial = async (material) => {

      let payload = {
          "user_initiator": {
            "usr_token": token
        },
        "resource": {
          ...material
         }
      }
  
      await updateResources(payload).then((resp) => {
          if (resp.success){
            setOpen(false);
            setForce(true)
          } else {
            alert(resp.message)
          }
        })
    };

    const handleEditClick = async (id) => {
      let payload = {
        "resource":{
          "resource_id":id
         }
      }
      await getInfoFromMarterial(payload).then((resp) => {
        if(resp.success){
          setSelectedMaterial(resp.data.resource);
          setOpen(true);
        }else {
          alert(resp.message)
        }
      })
    };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMaterial(null)
  };

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}}  onClick={handleClickOpen}>
        Добавить материал
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Укажите название"}
        </DialogTitle>
        <DialogContent>
           <AddVideoLectionsForm addMaterial={addMaterial} editData={selectedMaterial} handleUpdate={updateMaterial} />
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  const rows = handBooks.map(handbook => {
    return {
      id: handbook.resource_id,
      VideoTitle: handbook.title,
      VideoSubtitle: handbook.subtitle,
      type_id: handbook.type_id,
      ...handbook
    };
  });

  return (
    <Box m="20px">
    <Header title="Добавление видеолекции" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
       <DataGrid rows={rows} columns={columns} components={{ Toolbar: handleOpenAddDialog  }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />
       <Reader open={isReaderOpen} onClose={handleCloseReader} material={selectedMaterial} />
    </Box>
    </Box>
  );
};

export default videoLections;