import { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes

import {
  Button,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Grid,
  Box,
  Typography,
  Chip
} from "@mui/material";
import getIks from "../../../Api/HomeIk/getIks";
import { typesIds } from "../../../data/Utils/types";
import getInfoIk from '../../../Api/HomeIk/getInfoIk';
import { determineRoleCTYPE } from "../../../data/Utils/accessControlConst";
import { useSelector } from "react-redux";

const addElectionsForm = ({ addElection , selectedElection, updateElection}) => {
  addElectionsForm.propTypes = {
    addElection: PropTypes.func,
    updateElection: PropTypes.func,
  };
  
  const [elections, setElections] = useState({
    election_name: "",
    election_type: null,
    starts_at: "",
    ends_at: "",
    is_subdivided: false,
    home_ik: null,
    protocol_schema: [],
    roadmap_schema: null,
    incident_schema: "",
    meta: "",
    subdivisions:[],
    reg_ids:[],
    district_ids:[],
    pcount_deltas: [],
    protocol_deltas: [],
    election_system: null,
    is_deg: false,
    is_teg: false,
    is_network_list: false,
    target_el: null,
    is_territories: true,
    territories: [],
    ...selectedElection
  });

  //console.log("TEST", elections)
  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [selectedIk, setSelectedIk] = useState(null);
  const [selectedIks, setSelectedIks] = useState([]);
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const userInfo = useSelector((state) => state.user.user);

  const types = [
    { value: 1, label: "Федеральный" },
    { value: 2, label: "Региональный" },
    { value: 3, label: "Муниципальный" },
    { value: 4, label: "Сельсовет" },
  ];

  const targetElection = [
    { value: 1, label: "Глава" },
    { value: 2, label: "Депутат" },
  ];

  const electionSys = [
    { value: 1, label: "Единый округ" },
    { value: 2, label: "Мажоритарная" },
    { value: 3, label: "Смешанная" },
  ];

  // const [type, setType] = useState("");
  // const [isDeg, setIsDeg] = useState(elections.is_deg);
  // const [isTeg, setIsTeg] = useState(elections.is_teg);
  // const [isNetworkList, setIsNetworkList] = useState(elections.is_network_list);
  //const [yiks, setYiks] = useState([]);
  //const [typesIdsLocal, setTypesIdsLocal] = useState(typesIds)
  const token = useSelector((state) => state.user.token);

  //TODO: ДОДЕЛАТЬ ОБНОВЛЕНИЕ ВЫБОРОВ!
  
  // useEffect(()=> {
  //   let type = 0;
  //   if (elections.election_type === "Федеральный"){
  //      type = 1
  //   } else if (elections.election_type === "Региональный") {
  //      type = 2
  //   } else {
  //     type = 3
  //   }

  //   let payload = {
  //     "user_initiator":{
  //       "usr_token":token
  //     },
  //     "ik_type":{
  //       "type_id": type
  //     }
  //   }

  //   getIks(payload).then((resp) => {
  //       if (resp.success){
  //         setYiks(resp.data.iks)
  //       } else {
  //         setYiks([])
  //         alert(resp.message)
  //       }
  //   })

  // },[elections.election_type])

  useEffect(() => {

    if (selectedElection) {
      getInfoIk({
        "ik":{
          "ik_id": elections.home_ik
         }
      }).then((resp)=>{
         if(resp.success){
          setSelectedYik(resp.data.ik)
         } else {
           setSelectedYik([])
           alert(resp.message)
         }
      })

      if (
        elections.territories &&
        elections.territories.length > 0
      ) {
        Promise.all(
          elections.territories.map((value) =>
            getInfoIk({ ik: { ik_id: value } })
          )
        ).then((responses) => {
          const newData = responses.reduce((acc, resp) => {
            if (resp.success) {
              acc.push(resp.data.ik);
            } else {
              alert(resp.message);
            }
            return acc;
          }, []);
          setSelectedIks(newData);
        });
      }
    }
  }, [selectedElection]);

  const handleChangeType = (event) => {
    // setType(event.target.value);
    setElections({ ...elections, [event.target.name]: event.target.value });
  };

  const handleChangeElection = (e) => {
    console.log(e);
    setElections({ ...elections, [e.target.name]: e.target.value });
  };

  // const handleChangeQuestion = (event) => {
  //   setElections({ ...elections, [event.target.name]: event.target.checked });
  // };

  // const handleChangeQuestionDeg = (event) => {
  //   setElections({ ...elections, [event.target.name]: event.target.checked });
  // };

  // const handleChangeQuestionTeg = (event) => {
  //   setElections({ ...elections, [event.target.name]: event.target.checked });
  // };

  // const handleChangeQuestionNetworkList = (event) => {
  //   setElections({ ...elections, [event.target.name]: event.target.checked });
  // };
  
  const handleChangeQuestion = (event) => {
    setElections({ ...elections, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedElection) {
      // Handle editing existing election
      // Update the existing election data
      updateElection(elections)
    } else {
      // Handle adding new election
      addElection(elections);
    }
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;

    // Создаем объект Date из выбранного времени (локальное время пользователя)
    const dateObject = new Date(selectedTime);
  
    // Получаем временную зону пользователя
    const timezoneOffset = dateObject.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneOffsetString = `${timezoneOffset >= 0 ? '-' : '+'}${(timezoneOffsetHours < 10 ? '0' : '')}${timezoneOffsetHours}:${(timezoneOffsetMinutes < 10 ? '0' : '')}${timezoneOffsetMinutes}`;
  
    // Преобразуем время в UTC, учитывая временную зону пользователя
    dateObject.setMinutes(dateObject.getMinutes() - timezoneOffset);
    
    // Форматируем дату и время в формат ISO с учетом временной зоны пользователя
    const formattedTime = dateObject.toISOString().replace('Z', `${timezoneOffsetString}`);

    // Устанавливаем отформатированное значение в state
    setElections({ ...elections, [e.target.name]: formattedTime });
  };

 ///БЛОК ДЛЯ pcount_deltas

  const handleIntervalChange = (index, prop) => (event) => {
    const selectedTime = event.target.value;
    
    // Создаем объект Date из выбранного времени (локальное время пользователя)
    const dateObject = new Date(selectedTime);
  
    // Получаем временную зону пользователя
    const timezoneOffset = dateObject.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneOffsetString = `${timezoneOffset >= 0 ? '-' : '+'}${(timezoneOffsetHours < 10 ? '0' : '')}${timezoneOffsetHours}:${(timezoneOffsetMinutes < 10 ? '0' : '')}${timezoneOffsetMinutes}`;
  
    // Преобразуем время в UTC, учитывая временную зону пользователя
    dateObject.setMinutes(dateObject.getMinutes() - timezoneOffset);
    
    // Форматируем дату и время в формат ISO с учетом временной зоны пользователя
    const formattedTime = dateObject.toISOString().replace('Z', `${timezoneOffsetString}`);
  
    const updatedIntervals = [...elections.pcount_deltas];
    updatedIntervals[index] = { ...updatedIntervals[index], [prop]: formattedTime };
    setElections({ ...elections, pcount_deltas: updatedIntervals });
  };
  
  const handleAddInterval = (event) => {
    event.preventDefault();
    // Add the new interval to pcount_deltas
    setElections({
      ...elections,
      pcount_deltas: [...elections.pcount_deltas, { time_start: "", time_end: "" }],
    });
  };
  
  const handleDeleteInterval = (index) => {
    // Remove the interval at the specified index from pcount_deltas
    const updatedIntervals = [...elections.pcount_deltas];
    updatedIntervals.splice(index, 1);
    setElections({ ...elections, pcount_deltas: updatedIntervals });
  };

   ///БЛОК ДЛЯ protocol_deltas
 
   const handleIntervalChangeProtocol = (index, prop) => (event) => {
    const selectedTime = event.target.value;
    
    // Создаем объект Date из выбранного времени (локальное время пользователя)
    const dateObject = new Date(selectedTime);
  
    // Получаем временную зону пользователя
    const timezoneOffset = dateObject.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneOffsetString = `${timezoneOffset >= 0 ? '-' : '+'}${(timezoneOffsetHours < 10 ? '0' : '')}${timezoneOffsetHours}:${(timezoneOffsetMinutes < 10 ? '0' : '')}${timezoneOffsetMinutes}`;
  
    // Преобразуем время в UTC, учитывая временную зону пользователя
    dateObject.setMinutes(dateObject.getMinutes() - timezoneOffset);
    
    // Форматируем дату и время в формат ISO с учетом временной зоны пользователя
    const formattedTime = dateObject.toISOString().replace('Z', `${timezoneOffsetString}`);
  
    const updatedIntervals = [...elections.protocol_deltas];
    updatedIntervals[index] = { ...updatedIntervals[index], [prop]: formattedTime };
    setElections({ ...elections, protocol_deltas: updatedIntervals });
  };
  
  const handleAddIntervalProtocol = (event) => {
    event.preventDefault();
    // Add the new interval to pcount_deltas
    setElections({
      ...elections,
      protocol_deltas: [...elections.protocol_deltas, { ref_type_id: null, time_start: "", time_end: "" }],
    });
  };
  
  const handleDeleteIntervalProtocol = (index) => {
    // Remove the interval at the specified index from pcount_deltas
    const updatedIntervals = [...elections.protocol_deltas];
    updatedIntervals.splice(index, 1);
    setElections({ ...elections, protocol_deltas: updatedIntervals });
  };

  const handleChangeIntervalType = (index, prop) => (event) => {
    const selectedTypeId = event.target.value;
  
    // Проверяем, существует ли selectedTypeId в protocol_deltas
    const isTypeIdExists = elections.protocol_deltas.some(interval => interval.ref_type_id === selectedTypeId);
  
    if (isTypeIdExists) {
        alert("Для этого типа уже было указано время");
        return;
    }

    const updatedIntervals = [...elections.protocol_deltas];
    updatedIntervals[index] = { ...updatedIntervals[index], [prop]: selectedTypeId };
    setElections({ ...elections, protocol_deltas: updatedIntervals });
};

// const handleSearch = async (event) => {
//   if (event?.type === 'click' || event === null || event.target.value.length <= 2) {
//     return;
//   }

//   setSearchLoading(true);
  
//   const searchTerm = event.target.value;

//   try {
//     // Запрос списка yiks с сервера для текущей страницы
//     const response = await getIks({
//       "user_initiator": {
//         "usr_token": token
//       },
//       "ik": {
//         "type_id": elections.election_type,
//         "name": searchTerm,
//       },
//       //"page": currentPage
//     });
    
//     if(response.success){
//       const yiks = response.data.iks;
//       setFilteredYiks(yiks);
//       setSearchLoading(false);
//     }
//   } catch (error) {
//     console.error("Ошибка при получении списка yiks:", error);
//     // Обработка ошибки, например, отображение сообщения об ошибке пользователю
//     setSearchLoading(false);
//   }
// };

//Новая функция учитывающая логику поиска по типам выборов и автоочистку
const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

setSearchLoading(true);
  
  // Определяем список type_id на основе elections.election_type
  const typeIds = (() => {
    switch (elections.election_type) {
      case 1:
        return [1];
      case 2:
        return [1, 2];
      case 3:
        return [3];
      case 4:
        return [3, 4];
      default:
        return [];
    }
  })();

  // Выполняем запросы и объединяем результаты
  try {
    const requests = typeIds.map(typeId =>
      getIks({
        "user_initiator": { "usr_token": token },
        "ik": { "type_id": typeId, "name": searchTerm },
      })
    );

    // Ожидаем завершения всех запросов
    const responses = await Promise.all(requests);
    
    // Объединяем результаты
    const allYiks = responses.flatMap(response => response.success ? response.data.iks : []);
    
    setFilteredYiks(allYiks);
  } catch (error) {
    console.error("Ошибка при получении списка yiks:", error);
  } finally {
    setSearchLoading(false);
  }
};

const handleSearchForTerritories = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

setSearchLoading(true);

 //Временное решение проблемы для быстрого поиска ЦИКа
 try {
   // Запрос списка yiks с сервера для текущей страницы
   const response = await getIks({
     "user_initiator": {
       "usr_token": token
     },
     "ik": {
       "name": searchTerm,
     },
     //"page": currentPage
   });
   
   if(response.success){
     const yiks = response.data.iks;
     setFilteredYiks(yiks);
     setSearchLoading(false);
   }
 } catch (error) {
   console.error("Ошибка при получении списка yiks:", error);
   // Обработка ошибки, например, отображение сообщения об ошибке пользователю
   setSearchLoading(false);
 }
};

   // Обработчик выбора значения в Autocomplete
   const handleAutocompleteChange = (event, newValue) => {
    setSelectedYik(newValue);
    // Записываем значение УИКа в user.home_ik
    setElections({ ...elections, home_ik: newValue.ik_id });
  }


  // БЛОК С ОГРАНИЧИТЕЛЕМ ПО ТИПАМ:

  const filteredTypes = types.filter((type) => {
    const allowedAccess = determineRoleCTYPE(userInfo.access_data.access_id);
    switch (allowedAccess[0]) {
      case 35: // ROOT
      case 36: // Центральный super
      case 37: // Центральный admin
      case 38: // Центральный mod
        return true; // Видят все типы
      case 39: // Региональный super
      case 40: // Региональный admin
      case 41: // Региональный mod
        return type.value !== 1; // Исключить "Федеральный"
      case 42: // Районный super
      case 43: // Районный admin
      case 44: // Районный mod
        return type.value !== 2 && type.value !== 1; // Исключить "Региональный"
      default:
        return false; // Если доступ не указан, исключить все
    }
  });


  // Для поисковой строки с территориями

  const handleAddIksChip = () => {
    setElections({ ...elections, territories: [...elections.territories, selectedIk.ik_id] });
    setSelectedIks((prev)=> [...prev, selectedIk])
    setSelectedIk(null);
    setFilteredYiks([]); // Очищаем результаты поиска
  };

  const handleRemoveIkChip = (ikIdToRemove) => {
    // Удаляем выбранный ИК dataSub
    setElections(prevData => ({
        ...prevData,
        territories: prevData.territories.filter(ikId => ikId !== ikIdToRemove)
    }));

    // Удаляем выбранный ИК из selectedIks
    setSelectedIks(prevSelectedIks => prevSelectedIks.filter(ik => ik.ik_id !== ikIdToRemove));
};

const handleAutocompleteChangeTerritoies = (event, newValue) => {
  setSelectedIk(newValue);
 // Записываем значение УИКа в user.home_ik
}



  return (
    <Grid container spacing={2}>
  {/* Part 1: Левая колонка с информацией о выборах */}
  <Grid item xs={4}>
    <form onSubmit={handleSubmit}>
      <InputLabel id="label-election">Название выборов</InputLabel>
      <TextField
        id="label-election"
        margin="none"
        name="election_name"
        value={elections.election_name}
        onChange={handleChangeElection}
        fullWidth
      />
      <InputLabel id="datetime-local-starts_at">Дата начала проведения выборов</InputLabel>
      <TextField
        id="datetime-local-starts_at"
        type="datetime-local"
        name="starts_at"
        fullWidth
        value={elections.starts_at.slice(0, 16)}  // Обрезаем секунды и временную зону для отображения в поле ввода
        onChange={handleTimeChange}
        margin="none"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <InputLabel id="datetime-local-ends_at">Дата окончания проведения выборов</InputLabel>
      <TextField
        id="datetime-local-ends_at"
        type="datetime-local"
        name="ends_at"
        fullWidth
        value={elections.ends_at.slice(0, 16)}  // Обрезаем секунды и временную зону для отображения в поле ввода
        onChange={handleTimeChange}
        margin="none"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <InputLabel id="type-label">Уровень выборов</InputLabel>
      <Select
        labelId="type-label"
        id="type-selector"
        name="election_type"
        value={
          elections.election_type !== null 
            ? types.find((type) => type.value === elections.election_type).value
            : ""
        }
        fullWidth
        onChange={handleChangeType}
      >
        {filteredTypes.map((sub) => (
          <MenuItem key={sub.value} value={sub.value}>
            {sub.label}
          </MenuItem>
        ))}
      </Select>
      <InputLabel id="Autocomplete-label">Организующая комиссия</InputLabel>
      <FormControl fullWidth margin="none">
        <Autocomplete
          value={selectedYik}
          options={filteredYiks}
          loading={searchLoading}
          onChange={handleAutocompleteChange}
          onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} labelId="Autocomplete-label" fullWidth />}
          noOptionsText="Ничего не найдено"
          loadingText="Идет поиск. Пожалуйста, подождите!"
        />
      </FormControl>

      <Button type="submit" variant="contained" color="primary" margin="normal" sx={{marginTop: 5}}>
          {selectedElection !== null ? "Сохранить" : "Добавить"}
        </Button>
    </form>
  </Grid>

  {/* Part 2: Центральная колонка */}
  <Grid item xs={4}>
    <InputLabel id="elsystem-label">Тип выборов</InputLabel>
    <Select
      labelId="elsystem-label"
      id="elsystem-selector"
      name="election_system"
      value={
        elections.election_system !== null
          ? electionSys.find((type) => type.value === elections.election_system).value
          : ""
      }
      fullWidth
      onChange={handleChangeType}
    >
      {electionSys.map((sub) => (
        <MenuItem key={sub.value} value={sub.value}>
          {sub.label}
        </MenuItem>
      ))}
    </Select>
    <InputLabel id="targetEl-label">Кого выбираем ?</InputLabel>
    <Select
      labelId="targetEl-label"
      id="targetEl-selector"
      name="target_el"
      value={
        elections.target_el !== null
          ? targetElection.find((type) => type.value === elections.target_el).value
          : ""
      }
      fullWidth
      onChange={handleChangeType}
    >
      {targetElection.map((sub) => (
        <MenuItem key={sub.value} value={sub.value}>
          {sub.label}
        </MenuItem>
      ))}
    </Select>
    <FormControlLabel
      control={
        <Checkbox
          checked={elections.is_subdivided}
          name='is_subdivided'
          onChange={handleChangeQuestion}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Нужна ли нарезка на округа для этих выборов"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={elections.is_deg}
          name='is_deg'
          onChange={handleChangeQuestion}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="ДЭГ"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={elections.is_teg}
          name='is_teg'
          onChange={handleChangeQuestion}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Будут ли ТЭГи?"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={elections.is_network_list}
          name='is_network_list'
          onChange={handleChangeQuestion}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Присутствует ли электронный список?"
    />

    <FormControlLabel
      control={
        <Checkbox
          checked={elections.is_territories}
          name='is_territories'
          onChange={handleChangeQuestion}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Выборы проходят на всей территории?"
    />

   {!elections.is_territories &&
    <Box>

   <Typography variant="h6" gutterBottom sx={{mt: 2}}>Выбранные территории на которых пройдут выборы:</Typography>
     <Box border="1px solid black" borderRadius="4px" p="10px" maxHeight="300px" overflow="auto">
       {selectedIks.length > 0 && selectedIks.map((ik) => (
         <Chip key={ik.ik_id} label={ik.name} onDelete={() => handleRemoveIkChip(ik.ik_id)} style={{ margin: '5px' }} />
       ))}
     </Box>
    <Box sx={{marginTop: 2}}>
     <InputLabel id="Autocomplete-label">Поиск избирательных комиссии</InputLabel>
     <FormControl fullWidth margin="none">
     <Autocomplete
       //defaultValue={selectedYik}
       value={selectedIk}
       options={filteredYiks}
       loading={searchLoading}
       onChange={handleAutocompleteChangeTerritoies}
       onInputChange={(event, newValue, reason) => handleSearchForTerritories(event, newValue, reason)}
       getOptionLabel={(option) => option.name}
       renderInput={(params) => <TextField {...params} labelId="Autocomplete-label" fullWidth  />}
       noOptionsText="Ничего не найдено"
       loadingText="Идет поиск. Пожалуйста, подождите!"
    />
    </FormControl>
 </Box>

 <Button variant="contained" color="primary" onClick={handleAddIksChip} disabled={!selectedIk} sx={{marginTop: 2}}>
   Добавить
 </Button>
 </Box>
  }

  </Grid>

  {/* Part 3: Правая колонка с настройками времени */}
  <Grid item xs={4}>
    <h2>Настройка времени принятия явки</h2>
    {elections.pcount_deltas.map((interval, index) => (
      <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
        <div style={{ flex: 1 }}>
          <TextField
            type="datetime-local"
            value={interval.time_start.slice(0, 16)}
            onChange={handleIntervalChange(index, "time_start")}
            fullWidth
          />
          <TextField
            type="datetime-local"
            value={interval.time_end.slice(0, 16)}
            onChange={handleIntervalChange(index, "time_end")}
            fullWidth
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <Button onClick={() => handleDeleteInterval(index)} variant="contained" color="secondary" margin="normal">
            Удалить интервал
          </Button>
        </div>
      </div>
    ))}
    <Button variant="contained" color="primary" margin="normal" onClick={handleAddInterval} style={{ marginTop: 10 }}>
      Добавить интервал
    </Button>

    <h2>Настройка времени принятия протоколов</h2>
    {elections.protocol_deltas.map((interval, index) => (
      <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
        <div style={{ flex: 1 }}>
          <Select name="ref_type_id" value={interval.ref_type_id || ''} onChange={handleChangeIntervalType(index, "ref_type_id")}>
            {typesIds.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            type="datetime-local"
            value={interval.time_start.slice(0, 16)}
            onChange={handleIntervalChangeProtocol(index, "time_start")}
            fullWidth
          />
          <TextField
            type="datetime-local"
            value={interval.time_end.slice(0, 16)}
            onChange={handleIntervalChangeProtocol(index, "time_end")}
            fullWidth
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <Button onClick={() => handleDeleteIntervalProtocol(index)} variant="contained" color="secondary" margin="normal">
            Удалить интервал
          </Button>
        </div>
      </div>
    ))}
    {elections.protocol_deltas.length < 4 &&
    <Button variant="contained" color="primary" margin="normal" onClick={handleAddIntervalProtocol} style={{ marginTop: 10 }}>
      Добавить интервал
    </Button>
    }
  </Grid>
</Grid>
  );
};

export default addElectionsForm;
