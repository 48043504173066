import { useEffect, useState } from 'react';
import { Box, Button, Dialog ,DialogContent ,DialogTitle, useTheme  } from '@mui/material';
import { tokens } from '../../theme';
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddPartiesForm from "./addPartiesForm"
import getParties from '../../Api/Parties/getParties';
import addPartie from '../../Api/Parties/addPartie';
import deletePartie from '../../Api/Parties/deletePartie';
import { dataGridStyles } from '../../styleGrid';
import { useSelector } from 'react-redux';

const addParties = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [parties, setParties] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "partName",
      headerName: "Полное название партии",
      width: 400,
      cellClassName: "name-column--cell",
    },
    {
      field: "partNameShort",
      headerName: "Сокращенное название партии",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      flex:1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          style={{color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleDeleteCandidate(params.row.id)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (force)
    getParties({}).then((response) => {
      if (response.success){
        setParties(response.data.parties);
        setForce(false);
      } else {
        setParties([])
        alert(response.message)
        setForce(false);
      }
    });
  }, [force]);

  const rows = parties.map(partie => ({
    id: partie.id,
    partName: partie.name,
    partNameShort: partie.short_name,
  }));

  const addNewPartie = async (party) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
    },
       "party": party
    }
      addPartie(payload).then((resp) => {
         if (resp.success){
          setForce(true);
          setOpen(false);
         } else {
           alert(resp.message)
         }
      })
  };

  const handleDeleteCandidate = (id) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
    },
       "party": {
          "id":id
       }
    }
    deletePartie(payload).then((resp) => {
       if (resp.success) setForce(true);
       else alert(resp.message)
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        alert(error.message)
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить партию
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Укажите данные о партии"}
        </DialogTitle>
        <DialogContent>
           <AddPartiesForm addPartie={addNewPartie} />
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  return (
    <Box m="20px">
    <Header title="Добавление партий" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
       <DataGrid rows={rows} columns={columns} components={{ Toolbar: handleOpenAddDialog  }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />
    </Box>
    </Box>
  );
};

export default addParties;