import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
//import AddRoadMapForm from "./addRoadMapForm"
//import getInfoElections from '../../Api/Elections/getInfoElections';
//import getElections from '../../Api/Elections/getElections';
//import updateElections from '../../Api/Elections/updateElections';
import { dataGridStyles } from '../../styleGrid';
import { tokens } from '../../theme';
import AddRoadMapForm from './addRoadMapForm';
import getRoadMapSchemas from '../../Api/RoadMap/getRoadMapSchemas';
import getInfoRoadMapSchemas from '../../Api/RoadMap/getInfoRoadMapSchemas';
import createRoadMapSchemas from '../../Api/RoadMap/createRoadMapSchemas';
import updateRoadMapSchemas from '../../Api/RoadMap/updateRoadMapSchemas';
import deleteRoadMapSchemas from '../../Api/RoadMap/deleteRoadMapSchemas';
import { useSelector } from 'react-redux';

const roadMap = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [roadMaps, setRoadMaps] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [force, setForce] = useState(true);
  const token = useSelector((state) => state.user.token);
  const [selectedRoadmap, setSelectedRoadmap] = useState(null);

  const columns = [
    { field: "roadmap_id", headerName: "ID", width: 50 },
    {
      field: "anket_title",
      headerName: "Название дорожной карты",
      width: 500,
      cellClassName: "name-column--cell",
    },
    {
      field: "anket_sub_title",
      headerName: "Описание",
      width: 500,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      flex: 1,
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleEditClick(params.row)}
        >
          Редактировать
        </Button>

        <Button
          variant="outlined"
          style={{color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13}}
          onClick={() => handleDeleteRoadMap(params.row.id)}
        >
          Удалить
        </Button>
        </>
      ),
    },
  ];

  // function processRoadmapSchemas(roadmaps) {
  //   const result = [];
  
  //   roadmaps.forEach(roadmap => {
  //     const { roadmap_id, election_id} = roadmap;

  //     let payload = {
  //       "user_initiator":{
  //           "usr_token":token,
  //       },
  //       "el_roadmap": {
  //        "roadmap_id": roadmap_id
  //        }
  //     }
  
  //     if (roadmap_id) {
  //       try {
  //         getInfoRoadMapSchemas(payload).then((data) => {
  //             // Парсим JSON-строку в объект
  //             const roadmapObject = JSON.parse(data);
  
  //             // Добавляем в результат
  //             result.push({
  //               id: roadmap_id,
  //               election_id: election_id,
  //               ...roadmapObject,
  //             });
  //         })
  //       } catch (error) {
  //         console.error(`Error parsing protocol schema for roadmap_id ${roadmap_id}`, error);
  //       }
  //     }
  //   });
  
  //   return result;
  // }

  const getDataRoadmaps = async () => {

    let payload = {
      "user_initiator":{
        "usr_token": token
        }
    }

    await getRoadMapSchemas(payload).then((response) => {

      if (response.success) {
        //const protocolArrays = processRoadmapSchemas(response.data.el_protocols);
      const rows = response.data.el_roadmaps
      .map(roadmap => {
        return {
          id: roadmap.roadmap_id,
          ...roadmap
      };
    });

        //console.log("TEST TEST REPLANZ 0", rows)
        setRoadMaps(rows);
        setForce(false);
      } else {
        setRoadMaps([]);
        setForce(false);
        alert(response.message)
      }
    });
  }

  useEffect(() => {
    if(force) getDataRoadmaps();
  }, [force]);

  // const handleAddRoadMap = async (formData) => {
  //   try {
  //     // Стучимся в backend для получения выборов
  //     // const response = await getInfoElections({
  //     //   "election": {
  //     //     "election_id": formData.election_id
  //     //   }
  //     // });
  
  //     // Обработка ошибок
  //     // if (!response || !response.data || !response.data.election) {
  //     //   console.error("Error fetching elections data");
  //     //   return;
  //     // }

  //     //delete formData.election_id
  
  //     // Преобразование в строку протокола
  //     const roadmapDataString = JSON.stringify(formData);
  
  //     // Присоединяем протокол
  //     const updatedElection = {
  //           ...response.data.election,
  //           roadmap_schema: roadmapDataString
  //         };
  
  //     // Обновляем выборы и присоединяем к ним созданный протокол
  //     const dataToSend = {
  //       "user_initiator": {
  //         "usr_token": token
  //       },
  //       "election": {
  //         ...updatedElection // Directly use the first updated election
  //       }
  //     };
  
  //     // Вызываем backend для обновления выборов
  //     const updateResponse = await updateElections(dataToSend);
  
  //     if (updateResponse.success) {
  //       setForce(true);
  //       setOpenForm(false);
  //     }
  //   } catch (error) {
  //     // Обработка ошибок
  //     console.error("Error updating election:", error);
  //   }
  // };

  const createRoadmap =  async (formData) => {

    // Преобразование в строку протокола
    const roadmapDataString = JSON.stringify(formData);

    let payload = {
        "user_initiator":{
            "usr_token": token
        },
         "el_roadmap": {
         "anket_title": formData.anket_title,
         "anket_sub_title": formData.anket_sub_title,
         "election_id": formData.election_id,
         "data": roadmapDataString
          }
    }

    createRoadMapSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setForce(true);
        setOpenForm(false);
      } else{
        alert(resp.message)
      }
    })

  }

  const updateRoadmap =  async (formData) => {

    // Преобразование в строку протокола
    const roadmapDataString = JSON.stringify(formData);

    let payload = {
        "user_initiator":{
            "usr_token": token
        },
         "el_roadmap": {
            "roadmap_id": formData.id,
            "anket_title": formData.anket_title,
            "anket_sub_title": formData.anket_sub_title,
            "election_id": formData.election_id,
            "data": roadmapDataString
          }
    }

    updateRoadMapSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setSelectedRoadmap(null);
        setForce(true);
        setOpenForm(false);
      } else{
        alert(resp.message)
      }
    })

  }

  const handleDeleteRoadMap = (roadMapId) => {

    let payload = {
      "user_initiator":{
          "usr_token": token
      },
      "el_roadmap": {
       "roadmap_id": roadMapId
       }
      }

    deleteRoadMapSchemas(payload).then((resp) => {
      if (resp.success){
        alert(resp.data.sys_msg)
        setForce(true);
      } else {
        alert(resp.message)
      }
    })
  };

  const handleEditClick = async (roadmap) => {
       //const result = [];

        let payload = {
        "user_initiator":{
            "usr_token":token,
        },
        "el_roadmap": {
         "roadmap_id": roadmap.id
         }
      }

      await getInfoRoadMapSchemas(payload).then((resp) => {

        if (resp.success) {

          //console.log("TEST TEST 123", info)
            // Парсим JSON-строку в объект
            const roadmapObject = JSON.parse(resp.data.el_roadmap.data);

        
            // Добавляем в результат
            let obj = {
              ...roadmapObject,
              ...roadmap
            };

            setSelectedRoadmap(obj);
            setOpenForm(true);
        } else {
          alert(resp.message)
        }
    })
  };

  const handleClickOpen = () => {
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
    setSelectedRoadmap(null);
  };

  const handleOpenAddDialog = () => {
    return (
      <div>
        <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}}  onClick={handleClickOpen}>
          Добавить дорожную карту
        </Button>
        <Dialog
          open={openForm}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" sx={{backgroundColor: '#970D0D', color: 'white', fontSize: 17, fontWeight: 'bold'}}>
            {"Создайте форму будущей дорожной карты"}
          </DialogTitle>
          <DialogContent sx={{backgroundColor: 'white'}}>
            {/* <LazyAddRoadMapForm onSubmit={handleAddRoadMap} roadmapData={selectedRoadmap} onSave={handleAddRoadMap} /> */}
            <Box sx={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden'}}>
            <AddRoadMapForm onSubmit={createRoadmap} roadmapData={selectedRoadmap} onSave={updateRoadmap} />
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  return (
    <Box m="20px">
    <Header title="Создание дорожных карт" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
       <DataGrid 
           checkboxSelection 
           rows={roadMaps} 
           columns={columns} 
           components={{ Toolbar: handleOpenAddDialog  }} 
           localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
       />
    </Box>
    </Box>
  );
};

export default roadMap;