//import React from 'react';
import { Typography, Container, Grid, Box, Avatar, Button } from '@mui/material';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { dataGridStyles } from '../../styleGrid';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import navDown from '../../Api/IkNavigation/NavDown';
import Header from '../../components/Header';
import { ArrowBack } from "@mui/icons-material";

const CommissionProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ikData, setIkData] = useState(location.state.ikData);
    const [history, setHistory] = useState([]); // Хранение истории кликов

    console.log("TEST NAV BACK", history)

    // Функция для обработки клика на нижестоящую комиссию
    const handleClick = async (id) => {
        let payload = {
            nav: {
                ik_id: id,
            },
        };

        // Сохранение текущего id в историю перед переходом
        setHistory((prevHistory) => [...prevHistory, ikData.ik_data.ik_id]);

        // Выполнение запроса с использованием функции navDown
        await navDown(payload).then((resp) => {
            if (resp.success) {
                setIkData(resp.data.nav); // Обновление состояния с новыми данными
            } else {
                alert(resp.message); // Обработка ошибок
            }
        });
    };

    // Функция для обработки клика на кнопку "Назад"
    const handleBackClick = async () => {
        if (history.length > 0) {
            const lastId = history[history.length - 1]; // Получаем последний ID из истории
            setHistory((prevHistory) => prevHistory.slice(0, -1)); // Удаляем последний элемент из истории
    
            let payload = {
                nav: {
                    ik_id: lastId,
                },
            };
    
            // Выполняем запрос для предыдущего ID без добавления в историю
            await navDown(payload).then((resp) => {
                if (resp.success) {
                    setIkData(resp.data.nav); // Обновление состояния с данными для предыдущего ID
                } else {
                    alert(resp.message); // Обработка ошибок
                }
            });
        } else {
            navigate(-1); // Если история пуста, возвращаемся назад на предыдущую страницу
        }
    };

    // Функция для обработки клика на кнопку "Перейти к вышестоящей комиссии"
    const handleParentClick = () => {
        if (ikData.ik_data.parent_id) {
            handleClick(ikData.ik_data.parent_id); // Загружаем данные вышестоящей комиссии
        }
    };

    // Рендер кнопки "Назад"
    const backButton = () => (
        <Button size='medium' onClick={handleBackClick} startIcon={<ArrowBack />} sx={{ color: "black" }}>
        </Button>
    );


    return (
        <Box m="20px">
            <Header title="Информация о избирательной комиссии" backButton={backButton()} />
            <Box m="20px 0 0 0" height="80vh" sx={dataGridStyles}>
                <Container sx={{ py: 2 }}>
                    <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h3" fontWeight="bold">
                            {ikData.ik_data.name}
                        </Typography>
                        {/* Кнопка для перехода к вышестоящей комиссии */}
                        {ikData.ik_data.parent_id && (
                            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , fontWeight: 'bold', marginRight: 10}} onClick={handleParentClick} sx={{ ml: 4 }}>
                                Перейти к вышестоящей комиссии
                            </Button>
                        )}
                    </Box>

                    <Grid container spacing={3} sx={{ mb: 5 }}>
                        {[
                            { label: 'Адрес', value: ikData.ik_data.address },
                            { label: 'Телефон', value: ikData.ik_data.phone },
                            { label: 'Регион', value: ikData.ik_data.region_data.name },
                        ].map(({ label, value }) => (
                            <Grid item xs={6} key={label}>
                                <Box borderTop="1px solid #E0E0E0" py={2}>
                                    <Typography variant="h5" color="textSecondary">
                                        {label}
                                    </Typography>
                                    <Typography variant="h5">{value}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                        Члены избирательной комиссии
                    </Typography>

                    {ikData.users.map((user, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 2, backgroundColor: "#D3D1D1", p: 2, borderRadius: 2 }}
                        >
                            <Box display="flex" gap={2}>
                                <Avatar
                                    sx={{ width: 70, height: 70 }}
                                    src={`https://cdn-icons-png.flaticon.com/512/56/56745.png`}
                                />
                                <Box>
                                    <Typography variant="h5" fontWeight="medium" color="#970D0D">
                                        {user.usr_name}
                                    </Typography>
                                    <Typography variant="h5" color="#970D0D">
                                        {user.status_data?.name}, {user.access_data.access_display_name}, {user.usr_phone}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}

                    <Typography variant="h4" fontWeight="bold" sx={{ mt: 3, mb: 2 }}>
                        Комиссии входящие в состав:
                    </Typography>

                    {ikData.downstream_data.map((subordinate, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 2, backgroundColor: "#D3D1D1", p: 2, borderRadius: 2 }}
                            onClick={() => handleClick(subordinate.ik_id)} // Обработчик клика
                            style={{ cursor: 'pointer' }} // Указатель курсора при наведении
                        >
                            <Box display="flex" gap={2}>
                                <Box sx={{ backgroundColor: "#D3D1D1", borderRadius: '50%', p: 1 }}>
                                    <LocationOnIcon />
                                </Box>
                                <Box>
                                    <Typography variant="h5" fontWeight="medium" color="#970D0D">
                                        {subordinate.name}
                                    </Typography>
                                    <Typography variant="h5" color="#970D0D">
                                        {subordinate.region_data.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Container>
            </Box>
        </Box>
    );
};

export default CommissionProfile;