import { useEffect, useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Box, Container, Chip, Autocomplete, Typography, Grid, Select, MenuItem } from '@mui/material';
import getIks from '../../Api/HomeIk/getIks';
import PropTypes from "prop-types";
import getInfoIk from '../../Api/HomeIk/getInfoIk';
import { useSelector } from 'react-redux';
import { formatDate } from '../../data/Utils/timeZones';

const AddNotificationForm = ({onSubmit, selectedNotif}) => {
    AddNotificationForm.propTypes = {
        onSubmit: PropTypes.func.isRequired,
        selectedNotif: PropTypes.object
      };

  // console.log("TEST TEST", selectedNotif)

  const token = useSelector((state) => state.user.token);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedIk, setSelectedIk] = useState(null);
  const [selectedIks, setSelectedIks] = useState([]);
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [notificationData, setNotificationData] = useState({
    iks: [],
    tree: false,
    tree_levels: [],
    route: 'notificationScreen', // Дефолтное значение будет установлено
    type: '',
    sound: true,
    title: '',
    short_content: '',
    long_content: '',
    is_scheduled: false,
    created_at: null
  });

  const dataTypeIk = [
    {label: 'УИК', value: 4},
    {label: 'ТИК', value: 3},
    {label: 'РИК', value: 2},
    {label: 'ЦИК', value: 1},
  ]

  const dataTypeNotif = [
    {label: 'Информация', value: 'info'},
    {label: 'Напоминание', value: 'remind'},
    {label: 'Угроза', value: 'alert'},
  ]

  useEffect(() => {
    if (selectedNotif) {
      setNotificationData((prev) => ({...prev, ...selectedNotif}));
      //setSelectedYik(currentUser.ik_data)

      if (selectedNotif.iks?.length > 0) {
        Promise.all(
          selectedNotif.iks.map((value) =>
            getInfoIk({ ik: { ik_id: value } })
          )
        ).then((responses) => {
          const newData = responses.reduce((acc, resp) => {
            if (resp.success) {
              acc.push(resp.data.ik);
            } else {
              alert(resp.message);
            }
            return acc;
          }, []);
          setSelectedIks(newData);
        });
      }
    }
  }, [selectedNotif]);

  console.log("TEST TEST 2", notificationData)

  const getMinIkType = () => {
    if (selectedIks.length === 0) return null;

    const minIkType = selectedIks.reduce((minType, ik) => {
        const type = Number(ik.type_id); // Получаем тип ИК из имени

        if (minType === null || type < minType) {
            return type;
        } else {
            return minType;
        }
    }, null);

    return minIkType;
};

const minIkType = getMinIkType();

const handleInputChange = (e) => {
  const { name, value } = e.target;

  if (name === "scheduled_at") {
    const formattedDate = new Date(value).toISOString(); // Форматируем дату в ISO 8601
    setNotificationData({ ...notificationData, [name]: formattedDate });
  } else {
    setNotificationData({ ...notificationData, [name]: value });
  }
};

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if(name === 'tree'){
        if(checked){
                // Получаем значения всех доступных типов ИК
             const ikValues = dataTypeIk
                .filter(ik => ik.value >= (minIkType ? minIkType : 1))
                .map(ik => ik.value);
            // Устанавливаем флажки для всех доступных типов ИК
            setNotificationData({ ...notificationData, [name]: checked, tree_levels: ikValues });
        } else {
            setNotificationData({ ...notificationData, [name]: checked, tree_levels: [] });
        }
    } else if (name === 'is_scheduled'){
        if(checked){
            setNotificationData({ ...notificationData, [name]: checked, scheduled_at: null });
        } else {
            delete notificationData?.scheduled_at
            setNotificationData({ ...notificationData, [name]: checked });
        }
    } else {
        setNotificationData({ ...notificationData, [name]: checked });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь можно добавить логику отправки данных на сервер или другие действия
    onSubmit(notificationData);
  };

  const handleAddIksChip = () => {
    setNotificationData({ ...notificationData, iks: [...notificationData.iks, selectedIk.ik_id] });
    setSelectedIks((prev)=> [...prev, selectedIk])
    setSelectedIk(null);
  };

  const handleRemoveIkChip = (ikIdToRemove) => {
    // Удаляем выбранный ИК из notificationData
    setNotificationData(prevData => ({
        ...prevData,
        iks: prevData.iks.filter(ikId => ikId !== ikIdToRemove)
    }));

    // Удаляем выбранный ИК из selectedIks
    setSelectedIks(prevSelectedIks => prevSelectedIks.filter(ik => ik.ik_id !== ikIdToRemove));
};

const handleCheckboxIkChange = (event) => {
    const { checked, name } = event.target;
    const ikValue = parseInt(name.split('_')[1]); // Получаем значение ИК из имени

    if (checked) {
        // Добавляем значение ИК в массив tree_levels
        setNotificationData(prevData => ({
            ...prevData,
            tree_levels: [...prevData.tree_levels, ikValue]
        }));
    } else {
        // Удаляем значение ИК из массива tree_levels
        setNotificationData(prevData => ({
            ...prevData,
            tree_levels: prevData.tree_levels.filter(level => level !== ikValue)
        }));
    }
};

const handleNotificationTypeChange = (event) => {
    setNotificationData({ ...notificationData, type: event.target.value });
  };

     // Обработчик выбора значения в Autocomplete
const handleAutocompleteChange = (event, newValue) => {
     setSelectedIk(newValue);
    // Записываем значение УИКа в user.home_ik
  }
  
  const handleSearch = async (event, newValue, reason) => {

    if (reason === 'reset') {
      setFilteredYiks([]);
      return
    }
  
    if (event?.type === 'click' || event === null || event?.target === null) {
      return;
     }
  
     const searchTerm = event.target.value;
  
    // Проверка, если строка поиска пустая
    if (searchTerm.length === 0) {
      setFilteredYiks([]); // Очищаем результаты поиска
      return;
    }
  
    if (searchTerm.length <= 2) {
      return;
    }
  
  setSearchLoading(true);
  
    //Временное решение проблемы для быстрого поиска ЦИКа
    try {
      // Запрос списка yiks с сервера для текущей страницы
      const response = await getIks({
        "user_initiator": {
          "usr_token": token
        },
        "ik": {
          "name": searchTerm,
        },
        //"page": currentPage
      });
      
      if(response.success){
        const yiks = response.data.iks;
        setFilteredYiks(yiks);
        setSearchLoading(false);
      }
    } catch (error) {
      console.error("Ошибка при получении списка yiks:", error);
      // Обработка ошибки, например, отображение сообщения об ошибке пользователю
      setSearchLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{display:'flex',alignItems:'center', justyfiContent:'center', flexDirection:'column'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom sx={{mt: 5}}>Выбранные ИК для рассылки:</Typography>
            <Box border="1px solid black" borderRadius="4px" p="10px" maxHeight="300px" overflow="auto">
              {selectedIks.length > 0 && selectedIks.map((ik) => (
                <Chip key={ik.ik_id} label={ik.name} onDelete={() => handleRemoveIkChip(ik.ik_id)}  style={{ margin: '5px' }} {...(selectedNotif ? { onDelete: null } : {})} />
              ))}
            </Box>
        {selectedNotif === null && 
        <>
        <Box sx={{marginTop: 5}}>
          <Autocomplete
              //defaultValue={selectedYik}
              value={selectedIk}
              options={filteredYiks}
              loading={searchLoading}
              onChange={handleAutocompleteChange}
              onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label='Поиск по ИК' />}
              noOptionsText="Ничего не найдено"
              loadingText="Идет поиск. Пожалуйста, подождите!"
           />
        </Box>


            <Button variant="contained" color="primary" onClick={handleAddIksChip} disabled={!selectedIk} sx={{marginTop: 2}}>
              Добавить
            </Button>
            </>
}

            <FormControlLabel
  sx={{ ml: 5, mt: 2 }}
  control={
    <Checkbox
      checked={notificationData.tree} // Синхронизируем состояние чекбокса с tree из notificationData
      onChange={handleCheckboxChange}
      name="tree"
      sx={{
        "&.Mui-checked": {
          color: 'white',
        },
        "&.MuiCheckbox-root.Mui-disabled": {
          color: 'white', // Цвет чекбокса в состоянии disabled
        }
      }}
    />
  }
  label="Включить избирательные комиссии вниз по дереву"
  required
  disabled={!!selectedNotif}
/>

           {notificationData.tree &&
           <Box display='flex' flexDirection={'row'}>
             
             {dataTypeIk
                .filter(ik => ik.value >= (minIkType ? minIkType : 1))
                .map((ik, index) => (
                    <FormControlLabel
                        key={index}
                        sx={{ml: 5, mt: 2}}
                        control={<Checkbox checked={notificationData.tree_levels.includes(ik.value)} 
                        sx={{
                          "&.Mui-checked": {
                            color: 'white',
                          },
                          "&.MuiCheckbox-root.Mui-disabled": {
                            color: 'white', // Цвет чекбокса в состоянии disabled
                          }
                        }}
                        />} // Устанавливаем checked в зависимости от наличия значения в tree_levels
                        label={ik.label}
                        name={`tree_${ik.value}`} // Используем value как часть имени
                        onChange={handleCheckboxIkChange}
                    />
                ))
            }
           </Box>

            }

     <Typography variant="h6" gutterBottom sx={{mt: 3}}>Тип уведомления:</Typography>
      <Select
        value={notificationData.type}
        onChange={handleNotificationTypeChange}
        displayEmpty
        fullWidth
        disabled={selectedNotif}
        sx={{
          marginTop: '10px',
          "&.Mui-disabled": {
            color: 'white',
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: 'white',
            WebkitTextFillColor: 'white', // Для корректного отображения в некоторых браузерах
          }
        }}
      >
        {dataTypeNotif.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {/* Отображение времени отправки уведомления */}
{notificationData.created_at && (
  <Typography variant="body2" sx={{ mt: 3, color: 'white', fontSize: 16 }}>
    Отправлено: {formatDate(notificationData.created_at)}
  </Typography>
)}

          </Grid>

        <Grid item xs={12} md={6}>
    <Box mt={4}>

    <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Заголовок уведомления:</Typography>
<TextField
  name="title"
  fullWidth
  required
  variant="outlined"
  //margin="normal"
  value={notificationData.title}
  onChange={handleInputChange}
  disabled={selectedNotif}
  InputProps={{
    readOnly: selectedNotif,
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
  InputLabelProps={{
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
/>

<Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Короткая версия уведомления:</Typography>
<TextField
  name="short_content"
  fullWidth
  required
  multiline
  value={notificationData.short_content}
  rows={4}
  //margin="normal"
  onChange={handleInputChange}
  disabled={selectedNotif}
  InputProps={{
    readOnly: selectedNotif,
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
  InputLabelProps={{
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
/>

<Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Полная версия уведомления:</Typography>
<TextField
  name="long_content"
  fullWidth
  required
  variant="outlined"
  multiline
  value={notificationData.long_content}
  rows={4}
  //margin="normal"
  onChange={handleInputChange}
  disabled={selectedNotif}
  InputProps={{
    readOnly: selectedNotif,
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
  InputLabelProps={{
    sx: {
      color: 'white',
      "&.Mui-disabled": {
        color: 'white'
      },
      "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "white",
        },
    }
  }}
/>

      <FormControlLabel
        control={
        <Checkbox 
        sx={{
          "&.Mui-checked": {
            color: 'white',
          },
          "&.MuiCheckbox-root.Mui-disabled": {
            color: 'white', // Цвет чекбокса в состоянии disabled
          }
        }}
        />}
        label="Отложенное сообщение"
        name="is_scheduled"
        required
        onChange={handleCheckboxChange}
        disabled={!!selectedNotif}
        sx={{
          "&.Mui-checked": {
            color: 'white',
          },
          "&.MuiCheckbox-root.Mui-disabled": {
            color: 'white', // Цвет чекбокса в состоянии disabled
          }
        }}
      />

      {notificationData.is_scheduled &&

      <TextField
        name="scheduled_at"
        label="Время отправки"
        disabled={!!selectedNotif}
        type="datetime-local"
        fullWidth
        required
        variant="outlined"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleInputChange}
      />
    }
      </Box>

           </Grid>
        </Grid>
        
        {selectedNotif === null && 
        <Box mt="20px" textAlign="right">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Отправить уведомление
          </Button>
        </Box>
         }
      </Container>
  );
}

export default AddNotificationForm;